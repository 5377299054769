<template>
  <section class="new-dashboard-bg">
    <div class="row pr-32">
      <div class="col-md-3">
        <NewDashboardNavbar />
      </div>
      <div class="col-md-9">
        <div class="d-flex justify-content-between">
          <Search />
          <div class="d-flex">
            <Notification />
            <Avatar />
          </div>
        </div>

        <DashboardTitlte title="Dashboard" />

        <CardComponent title="Total SIM" value="856" />
        <CardComponent title="Assigned SIM" value="656" />
        <CardComponent title="Destroyed SIM" value="200" />
        <CardComponent title="Current Week Sales" value="78" />
        <CardComponent title="Current Month Sales" value="346" />
        <CardComponent title="Yeast to Date Sales" value="12" />

        <!-- <BarChart /> -->
        <!-- <DoughnutChart /> -->
      </div>
    </div>
  </section>
</template>

<script>
import Search from "../../sections/NewDashboard/Search.vue";
import NewDashboardNavbar from "../../../components/layouts/NewDashboardNavbar.vue";
import DashboardTitlte from "../../../components/helpers/DashboardTitle.vue";
import CardComponent from "../../../components/helpers/CardComponent.vue";
import Notification from "../../../components/sections/NewDashboard/Notification.vue";
import Avatar from "../../../components/sections/NewDashboard/Avatar.vue";

// import BarChart from "../../../components/helpers/BarChart.vue";
// import DoughnutChart from "../../../components/helpers/DoughnutChart.vue";

export default {
  components: {
    Search,
    NewDashboardNavbar,
    DashboardTitlte,
    CardComponent,
    Notification,
    Avatar,
  },
  data() {
    return {};
  },
  mounted() {
    this.setDashboardTab();
  },
  methods: {
    setDashboardTab() {
      this.$store.dispatch("addDashboardTab", 1);
    },
  },
};
</script>

<style>
@import "../../../assets/styles/components/pages/NewDashboard/NewDashboard.css";
</style>
