<template>
  <section class="mt-24">
    <div class="input-search-section">
      <input type="text" placeholder="Search..." />
    </div>
  </section>
</template>

<script></script>

<style>
@import "../../../assets/styles/components/pages/NewDashboard/Search.css";
</style>
