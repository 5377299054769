<template>
  <section class="new-dashboard-bg">
    <div class="row pr-32">
      <div class="col-md-3">
        <NewDashboardNavbar />
      </div>
      <div class="col-md-9">
        <div class="d-flex justify-content-between">
          <Search />
          <div class="d-flex">
            <Notification />
            <Avatar />
          </div>
        </div>

        <DashboardTitlte title="Revenue Report" />

        <div class="dashboard-content mb-16 backdown-13-16">
          <div class="d-flex">
            <div class="flex-item">
              <div class="mr-16">
                <label>Start Date</label>
                <input class="w-100" type="date" />
              </div>
            </div>
            <div class="flex-item">
              <div class="mr-16">
                <label>End Date</label>
                <input class="w-100" type="date" />
              </div>
            </div>
            <div class="flex-item">
              <div class="mr-16">
                <label>Reseller</label>
                <div class="position-relative">
                  <div
                    :class="[
                      IsDropdownListActive == false
                        ? 'dropdown-text'
                        : 'dropdown-text dropdown-input-text--open',
                    ]"
                  >
                    <div class="position-relative">
                      <input
                        @click="showDropdownList()"
                        :value="this.itemValueSelected"
                        class="input-dropdown-text"
                        id="list"
                        list="countryList"
                        :placeholder="placeholder"
                      />
                      <div
                        :class="[
                          IsDropdownListActive == false
                            ? 'd-none'
                            : 'dropdown-list',
                        ]"
                      >
                        <div @click="selectItem('Any')">Any</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-item">
              <div class="mr-16">
                <label>Plan</label>
                <div class="position-relative">
                  <div
                    :class="[
                      IsDropdownListActive == false
                        ? 'dropdown-text'
                        : 'dropdown-text dropdown-input-text--open',
                    ]"
                  >
                    <div class="position-relative">
                      <input
                        @click="showDropdownList2()"
                        :value="this.itemValueSelected"
                        class="input-dropdown-text"
                        id="list"
                        list="countryList"
                        :placeholder="placeholder"
                      />
                      <div
                        :class="[
                          IsDropdownListActive == false
                            ? 'd-none'
                            : 'dropdown-list',
                        ]"
                      >
                        <div @click="selectItem2('Any')">Any</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="dashboard-content mb-16 backdown-16-24">
          <div class="d-flex mb-16">
            <div class="mr-24 pt-5">Group by:</div>
            <div>
              <input
                type="radio"
                class="btn-check"
                name="options"
                id="option1"
                autocomplete="off"
                checked
              />
              <label class="btn btn-light" for="option1">Subscriber</label>

              <input
                type="radio"
                class="btn-check"
                name="options"
                id="option2"
                autocomplete="off"
              />
              <label class="btn btn-light" for="option2">Plan</label>

              <input
                type="radio"
                class="btn-check"
                name="options"
                id="option3"
                autocomplete="off"
              />
              <label class="btn btn-light" for="option3">Day</label>

              <input
                type="radio"
                class="btn-check"
                name="options"
                id="option4"
                autocomplete="off"
              />
              <label class="btn btn-light" for="option4">Reseller</label>
            </div>
          </div>

          <div>
            <table class="table table-bordered dashboard-content-table">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Prepay purchase</th>
                  <th scope="col">new purchase</th>
                  <th scope="col">renew purchase</th>
                  <th scope="col">top up purchase</th>
                </tr>
              </thead>
              <tbody>
                <template
                  :key="index"
                  v-for="(simSubscription, index) in SIMForAdministrator"
                >
                  <tr>
                    <th scope="row">Dec 18, 2022</th>
                    <td>877</td>
                    <td>9374</td>
                    <td>8013</td>
                    <td>5948</td>
                    <td>
                      <div
                        v-if="
                          simSubscription.simSubscription.planSubscription.plan
                            .isActive == true
                        "
                        class="table-sim-active"
                      >
                        Active
                      </div>
                      <div
                        v-if="
                          simSubscription.simSubscription.planSubscription.plan
                            .isActive == false
                        "
                        class="table-sim-not-active"
                      >
                        Not Active
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Search from "../../sections/NewDashboard/Search.vue";
import NewDashboardNavbar from "../../layouts/NewDashboardNavbar.vue";
import DashboardTitlte from "../../helpers/DashboardTitle.vue";
import Notification from "../../sections/NewDashboard/Notification.vue";
import Avatar from "../../sections/NewDashboard/Avatar.vue";

export default {
  components: {
    Search,
    NewDashboardNavbar,
    DashboardTitlte,
    Notification,
    Avatar,
  },
  data() {
    return {
      IsDropdownListActive: false,
      itemValueSelected: "",
    };
  },
  mounted() {
    this.setDashboardTab();
  },
  methods: {
    setDashboardTab() {
      this.$store.dispatch("addDashboardTab", 9);
    },
    selectItem(itemValueSelected) {
      this.itemValueSelected = itemValueSelected;
      this.IsDropdownListActive = false;
    },
    showDropdownList() {
      this.IsDropdownListActive = !this.IsDropdownListActive;
    },
  },
};
</script>

<style>
@import "../../../assets/styles/components/pages/NewDashboard/NewDashboard.css";
</style>
