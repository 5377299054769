export const getToken = () => {
  return localStorage.getItem("TOKEN");
};

export const saveToken = (token) => {
  localStorage.setItem("TOKEN", token);
};

export const destroyToken = () => {
  localStorage.removeItem("TOKEN");
};

export const checkToken = () => {
  if (getToken() === null) window.location.href = String("/#/LoginPage");
};

export default { getToken, saveToken, destroyToken, checkToken };
