<template>
  <div class="new-dashboard-navbar">
    <div class="new-dashboard-logo">
      <img :src="iconLogo" alt="" />
    </div>
    <div>
      <div
        :class="[
          dashboardTab == 1
            ? 'new-dashboard-navbar--menu-item new-dashboard-navbar--menu-item-active'
            : 'new-dashboard-navbar--menu-item',
        ]"
      >
        <img
          class="new-dashboard-navbar--image"
          :src="[dashboardTab == 1 ? iconDashboardGreen : iconDashboard]"
          alt=""
        />
        <router-link
          :class="[
            dashboardTab == 1
              ? 'new-dashboard-navbar--link new-dashboard-navbar--link-active'
              : 'new-dashboard-navbar--link',
          ]"
          to="/NewDashboardPage"
          >Dashboard</router-link
        >
      </div>
      <div
        :class="[
          dashboardTab == 2
            ? 'new-dashboard-navbar--menu-item new-dashboard-navbar--menu-item-active'
            : 'new-dashboard-navbar--menu-item',
        ]"
      >
        <img
          class="new-dashboard-navbar--image"
          :src="[dashboardTab == 2 ? iconSimsGreen : iconSims]"
          alt=""
        />
        <router-link
          :class="[
            dashboardTab == 2
              ? 'new-dashboard-navbar--link new-dashboard-navbar--link-active'
              : 'new-dashboard-navbar--link',
          ]"
          to="/NewSIMSPage"
          >SIMs</router-link
        >
      </div>
      <div
        :class="[
          dashboardTab == 3
            ? 'new-dashboard-navbar--menu-item new-dashboard-navbar--menu-item-active'
            : 'new-dashboard-navbar--menu-item',
        ]"
      >
        <img
          class="new-dashboard-navbar--image"
          :src="[dashboardTab == 3 ? iconSubscribersGreen : iconSubscribers]"
          alt=""
        />
        <router-link
          :class="[
            dashboardTab == 3
              ? 'new-dashboard-navbar--link new-dashboard-navbar--link-active'
              : 'new-dashboard-navbar--link',
          ]"
          to="/NewSubscribersPage"
          >Subscribers</router-link
        >
      </div>
      <div
        :class="[
          dashboardTab == 4
            ? 'new-dashboard-navbar--menu-item new-dashboard-navbar--menu-item-active'
            : 'new-dashboard-navbar--menu-item',
        ]"
      >
        <img
          class="new-dashboard-navbar--image"
          :src="[dashboardTab == 4 ? iconResellerGreen : iconReseller]"
          alt=""
        />
        <router-link
          :class="[
            dashboardTab == 4
              ? 'new-dashboard-navbar--link new-dashboard-navbar--link-active'
              : 'new-dashboard-navbar--link',
          ]"
          to="/NewResellerPage"
          >Reseller</router-link
        >
      </div>
      <div
        :class="[
          dashboardTab == 5
            ? 'new-dashboard-navbar--menu-item new-dashboard-navbar--menu-item-active'
            : 'new-dashboard-navbar--menu-item',
        ]"
      >
        <img
          class="new-dashboard-navbar--image"
          :src="[dashboardTab == 5 ? iconPaymentGreen : iconPayment]"
          alt=""
        />
        <router-link
          :class="[
            dashboardTab == 5
              ? 'new-dashboard-navbar--link new-dashboard-navbar--link-active'
              : 'new-dashboard-navbar--link',
          ]"
          to="/NewPaymentPage"
          >Payment</router-link
        >
      </div>
      <div
        :class="[
          dashboardTab == 6
            ? 'new-dashboard-navbar--menu-item new-dashboard-navbar--menu-item-active'
            : 'new-dashboard-navbar--menu-item',
        ]"
      >
        <img
          class="new-dashboard-navbar--image"
          :src="[dashboardTab == 6 ? iconInvoiceGreen : iconInvoice]"
          alt=""
        />
        <router-link
          :class="[
            dashboardTab == 6
              ? 'new-dashboard-navbar--link new-dashboard-navbar--link-active'
              : 'new-dashboard-navbar--link',
          ]"
          to="/NewInvoicePage"
          >Invoice</router-link
        >
      </div>
      <div
        :class="[
          dashboardTab == 7
            ? 'new-dashboard-navbar--menu-item new-dashboard-navbar--menu-item-active'
            : 'new-dashboard-navbar--menu-item',
        ]"
      >
        <img
          class="new-dashboard-navbar--image"
          :src="[dashboardTab == 7 ? iconFulfilmentGreen : iconFulfilment]"
          alt=""
        />
        <router-link
          :class="[
            dashboardTab == 7
              ? 'new-dashboard-navbar--link new-dashboard-navbar--link-active'
              : 'new-dashboard-navbar--link',
          ]"
          to="/NewFulfilmentPage"
          >Fulfilment</router-link
        >
      </div>
      <div
        :class="[
          dashboardTab == 8
            ? 'new-dashboard-navbar--menu-item new-dashboard-navbar--menu-item-active'
            : 'new-dashboard-navbar--menu-item',
        ]"
      >
        <img
          class="new-dashboard-navbar--image"
          :src="[dashboardTab == 8 ? iconPlanGreen : iconPlan]"
          alt=""
        />
        <router-link
          :class="[
            dashboardTab == 8
              ? 'new-dashboard-navbar--link new-dashboard-navbar--link-active'
              : 'new-dashboard-navbar--link',
          ]"
          to="/NewPlanPage"
          >Plan</router-link
        >
      </div>
      <div
        :class="[
          dashboardTab == 9
            ? 'new-dashboard-navbar--menu-item new-dashboard-navbar--menu-item-active new-dashboard-navbar--dropdown'
            : 'new-dashboard-navbar--menu-item new-dashboard-navbar--dropdown',
        ]"
      >
        <img
          class="new-dashboard-navbar--image"
          :src="[dashboardTab == 9 ? iconReportsGreen : iconReports]"
          alt=""
        />
        <div
          :class="[
            dashboardTab == 9
              ? 'new-dashboard-navbar--link new-dashboard-navbar--menu-item-dropdown new-dashboard-navbar--link-active'
              : 'new-dashboard-navbar--link new-dashboard-navbar--menu-item-dropdown',
          ]"
          to="/NewRevenueReportsPage"
        >
          Reports
        </div>
        <ul class="new-dashboard-navbar--menu-item-dropdown--list">
          <li>
            <router-link to="/NewRevenueReportsPage"
              >Revenue Report</router-link
            >
          </li>
          <li>
            <router-link to="/NewUsageReportsPage">Usage Report</router-link>
          </li>
          <li>
            <router-link to="/NewCostReportsPage">Cost Report</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="hide-dashboard-img">
      <img :src="hideDashboard" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {
    dashboardTab: {
      get() {
        return this.$store.state.DashboardNewTab.dashboardTab;
      },
    },
  },
  data() {
    return {
      iconLogo: require("../../assets/images/components/header/Peering-Color-Logo.svg"),
      hideDashboard: require("../../assets/images/pages/NewDashboardPage/hideDashboard.svg"),

      iconDashboard: require("../../assets/images/pages/NewDashboardPage/dashboard.svg"),
      iconSims: require("../../assets/images/pages/NewDashboardPage/sims.svg"),
      iconSubscribers: require("../../assets/images/pages/NewDashboardPage/subscribers.svg"),
      iconReseller: require("../../assets/images/pages/NewDashboardPage/reseller.svg"),
      iconPayment: require("../../assets/images/pages/NewDashboardPage/payment.svg"),
      iconInvoice: require("../../assets/images/pages/NewDashboardPage/invoice.svg"),
      iconFulfilment: require("../../assets/images/pages/NewDashboardPage/fulfilment.svg"),
      iconPlan: require("../../assets/images/pages/NewDashboardPage/plan.svg"),
      iconReports: require("../../assets/images/pages/NewDashboardPage/reports.svg"),

      iconDashboardGreen: require("../../assets/images/pages/NewDashboardPage/dashboardGreen.svg"),
      iconSimsGreen: require("../../assets/images/pages/NewDashboardPage/simsGreen.svg"),
      iconSubscribersGreen: require("../../assets/images/pages/NewDashboardPage/subscribersGreen.svg"),
      iconResellerGreen: require("../../assets/images/pages/NewDashboardPage/resellerGreen.svg"),
      iconPaymentGreen: require("../../assets/images/pages/NewDashboardPage/paymentGreen.svg"),
      iconInvoiceGreen: require("../../assets/images/pages/NewDashboardPage/invoiceGreen.svg"),
      iconFulfilmentGreen: require("../../assets/images/pages/NewDashboardPage/fulfilmentGreen.svg"),
      iconPlanGreen: require("../../assets/images/pages/NewDashboardPage/planGreen.svg"),
      iconReportsGreen: require("../../assets/images/pages/NewDashboardPage/reportsGreen.svg"),
    };
  },
};
</script>

<style>
@import "../../assets/styles/components/layouts/NewDashboardNavbar.css";
</style>
