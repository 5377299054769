<template>
  <section class="mt-24">
    <img :src="iconNotification" alt="" />
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      iconNotification: require("../../../assets/images/components/notification/notification.svg"),
    };
  },
};
</script>

<style></style>
