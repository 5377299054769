import ApiService from "../api.service";

const LoginService = {
  postAdministratorLogin(payload) {
    return ApiService.post(
      `${process.env.VUE_APP_API_LINK}/administrator/login`,
      {
        username: payload.username,
        password: payload.password,
      }
    );
  },
  postReesellerLogin(payload) {
    return ApiService.post(`${process.env.VUE_APP_API_LINK}/reseller/login`, {
      username: payload.username,
      password: payload.password,
    });
  },
  postSubscriberLogin(payload) {
    return ApiService.post(`${process.env.VUE_APP_API_LINK}/public/login`, {
      username: payload.username,
      password: payload.password,
    });
  },
};

export default LoginService;
