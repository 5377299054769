import ApiService from "../api.service";

const CallLogsService = {
  postSubscriberSimCallLogById(simId) {
    return ApiService.get(
      `${process.env.VUE_APP_API_LINK}/subscriber/sim/${simId}/call_log`,
      {}
    );
  },
};

export default CallLogsService;
