<template>
  <section class="section section--bottom--space">
    <div>
      <div class="general-card">
        <div class="table-responsive">
          <table class="table table-sim">
            <thead>
              <tr>
                <td scope="col" class="fw-500">Order UUID</td>
                <td scope="col" class="fw-500">Plan number</td>
                <td scope="col" class="fw-500">Status</td>
                <td scope="col" class="fw-500">Tracking #</td>
              </tr>
            </thead>
            <tbody>
              <template :key="index" v-for="(data, index) in orderStatus">
                <tr>
                  <td>{{ data.id }}</td>
                  <td>
                    {{ data.planId }}
                  </td>
                  <td>
                    <div
                      v-if="
                        data.createdAt !== null && data.activatedAt === null
                      "
                      class="pendingStatus"
                    >
                      Pending
                    </div>
                    <div v-if="data.activatedAt !== null" class="doneStatus">
                      Active
                    </div>
                  </td>
                  <td>{{ data.trackingCode }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      orderStatus: {},
    };
  },
  components: {},
  mounted() {
    this.fetchOrders();
  },
  methods: {
    async fetchOrders() {
      await this.$store.dispatch("addOrders");
      this.orderStatus = this.$store.state.DashobardOrderStatusStore.orders;
    },
  },
};
</script>

<style>
@import "../../../assets/styles/components/sections/Dashboard/06_DashobardOrderStatus.css";
</style>
