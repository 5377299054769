<template>
  <div
    :class="[showModal == true ? 'd-block modal' : 'd-none']"
    id="ExtendModal"
  >
    <div
      :class="[showModal == true ? 'd-block modal-dialog' : 'd-none']"
      role="document"
    >
      <div class="modal-content">
        <button
          type="button"
          class="btn-close"
          v-on:click="this.sentToParent()"
        ></button>
        <div class="modal-header">
          <h4>Define Card</h4>
        </div>
        <div class="modal-body">
          <div class="d-flex">
            <div class="flex-item">
              <div class="mb-16">
                <div class="mb-8">
                  Name on Card <span class="label-required">*</span>
                </div>
                <div>
                  <input placeholder="John Doe" type="text" class="mr-8" />
                </div>
              </div>
              <div class="mb-16">
                <div class="mb-8">
                  Expiry Date <span class="label-required">*</span>
                </div>
                <div>
                  <input placeholder="MM / YY" type="text" class="mr-8" />
                </div>
              </div>
              <div class="mb-16">
                <div class="mb-8">
                  Address 1 <span class="label-required">*</span>
                </div>
                <div>
                  <input
                    placeholder="Enter card number"
                    type="text"
                    class="mr-8"
                  />
                </div>
              </div>
              <div class="mb-16">
                <div class="mb-8">
                  City <span class="label-required">*</span>
                </div>
                <div>
                  <input
                    placeholder="Enter card number"
                    type="text"
                    class="mr-8"
                  />
                </div>
              </div>
              <div class="mb-16">
                <div class="mb-8">
                  Phone Number <span class="label-required">*</span>
                </div>
                <div>
                  <input
                    placeholder="Enter card number"
                    type="text"
                    class="mr-8"
                  />
                </div>
              </div>
            </div>
            <div class="flex-item">
              <div class="mb-16">
                <div class="mb-8">
                  Card Number <span class="label-required">*</span>
                </div>
                <div>
                  <input
                    placeholder="Enter card number"
                    type="text"
                    class="mr-8"
                  />
                </div>
              </div>
              <div class="input-helper">
                <div class="mb-16">
                  <div class="mb-8">
                    Security code <span class="label-required">*</span>
                  </div>
                  <div>
                    <input placeholder="*** " type="text" class="mr-8" />
                  </div>
                </div>
              </div>
              <div class="mb-16">
                <div class="mb-8">
                  Address 2 <span class="label-required">*</span>
                </div>
                <div>
                  <input
                    placeholder="Enter card number"
                    type="text"
                    class="mr-8"
                  />
                </div>
              </div>
              <div class="input-helper">
                <div class="mb-16">
                  <div class="mb-8">
                    ZIP/Postal Code <span class="label-required">*</span>
                  </div>
                  <div>
                    <input placeholder="00-000" type="text" class="mr-8" />
                  </div>
                </div>
              </div>
              <div class="mb-16">
                <div class="mb-8">
                  State <span class="label-required">*</span>
                </div>
                <div>
                  <input placeholder="Select State" type="text" class="mr-8" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            v-on:click="this.redirectToPaymnet()"
            class="btn-round btn-green"
          >
            Save and Continue
          </button>
        </div>
      </div>
    </div>
  </div>
  <div :class="[showModal == true ? 'modal-bg' : 'd-none']"></div>
</template>

<script>
export default {
  props: ["showModal"],
  methods: {
    sentToParent() {
      this.$parent.$data.showModal = false;
    },
    redirectToPaymnet() {
      this.$parent.setActiveItemId("3");
      this.$parent.$data.showModal = false;
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
@import "../../../assets/styles/helpers/Modals/DefineCardModal.css";
</style>
