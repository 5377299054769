<template>
  <section class="section section-profile">
    <div>
      <h3>Eligibility</h3>
      <div class="general-card">
        <div class="mb-32">
          <label
            >What government Program do you Participate in?
            <span class="label-required">*</span></label
          >
          <div class="option-border">
            <input
              type="radio"
              name="optionProfile"
              value="1"
              @change="this.setOptionItemId('1')"
            />
            <span>Medaicaid</span>
          </div>
          <div class="option-border">
            <input
              type="radio"
              name="optionProfile"
              value="2"
              @change="this.setOptionItemId('2')"
            />
            <span
              >Supplemental Nutrition Assistance Program (SNAP) (f/k/a Food
              Stamps)</span
            >
          </div>
          <div class="option-border">
            <input
              type="radio"
              name="optionProfile"
              value="3"
              @change="this.setOptionItemId('3')"
            />
            <span>Supplemental Security Income (SSI)</span>
          </div>
          <div class="option-border">
            <input
              type="radio"
              name="optionProfile"
              value="4"
              @change="this.setOptionItemId('4')"
            />
            <span>Veternas Pension and Survivors benefit Program</span>
          </div>
          <div class="option-border">
            <input
              type="radio"
              name="optionProfile"
              value="5"
              @change="this.setOptionItemId('5')"
            />
            <span>Federal Pell Grant</span>
            <div
              :class="[
                optionIndex == 5 ? 'optionProfile-card--no-border' : 'd-none ',
              ]"
            >
              <div>
                <label>School Name <span class="label-required">*</span></label>
                <input placeholder="School Name" type="text" class="w-95" />
              </div>
            </div>
          </div>
          <div class="option-border">
            <input
              type="radio"
              name="optionProfile"
              value="6"
              @change="this.setOptionItemId('6')"
            />
            <span
              >Special Supplemental Nutrition Program for Women, infants, and
              Chilredn (WIC)</span
            >
          </div>
          <div class="option-border">
            <input
              type="radio"
              name="optionProfile"
              value="7"
              @change="this.setOptionItemId('7')"
            />
            <span>Federal Publing Housing Assistance</span>
            <div :class="[optionIndex == 7 ? 'ml-26' : 'd-none  ml-52']">
              <div>
                <input
                  type="radio"
                  id="federal"
                  name="optionProfileFederal"
                  value="1"
                /><span>Hosuding choice voucher program</span>
              </div>
              <div>
                <input
                  type="radio"
                  id="federal"
                  name="optionProfileFederal"
                  value="2"
                /><span>Project based rental assistance</span>
              </div>
              <div>
                <input
                  type="radio"
                  id="federal"
                  name="optionProfileFederal"
                  value="3"
                /><span>Public houssing</span>
              </div>
              <div>
                <input
                  type="radio"
                  id="federal"
                  name="optionProfileFederal"
                  value="4"
                /><span
                  >Affordable Housing Programs for American indians, Alaska,
                  Natives or Native Hawaiians</span
                >
              </div>
            </div>
          </div>
          <div class="option-border option-border--unemployment">
            <input
              type="radio"
              name="optionProfile"
              value="8"
              @change="this.setOptionItemId('8')"
            />
            <span>Qualify based on Income / Unemployment</span>
            <div
              :class="[
                optionIndex == 8
                  ? 'flex optionProfile-card--no-border'
                  : 'd-none',
              ]"
            >
              <div>
                <div class="mb-16">
                  How many adults (18 and over) and children (under 18)
                  currently reside
                </div>
                <div class="mb-16">
                  <select class="form-select" aria-label="Select adult number">
                    <option selected></option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    <option value="4">Four</option>
                    <option value="5">Five</option>
                  </select>
                </div>
                <div class="mb-16">
                  <select class="form-select" aria-label="Select child number">
                    <option selected></option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    <option value="4">Four</option>
                    <option value="5">Five</option>
                  </select>
                </div>
                <div class="mb-16">
                  <label>Select income period</label>
                  <select class="form-select" aria-label="Select income period">
                    <option value="1" selected>Monthly</option>
                    <option value="2">Yearly</option>
                  </select>
                </div>
                <div>
                  <label
                    >Total annual gross income (round to whole dollars, check
                    the income Calculator in the Eligibility Guidelines)
                    <span class="label-required">*</span>
                  </label>
                  <input
                    placeholder="Enter your income"
                    type="text"
                    class="w-95"
                  />
                </div>
              </div>
              <div class="optionProfile-card">
                <div class="d-flex mb-16">
                  <div>
                    People living in your hosusehoid (including yourself)
                  </div>
                  <div class="fw-600">0</div>
                </div>
                <div class="d-flex mb-16">
                  <div>Federal Poverty Guldeline</div>
                  <div class="fw-600 main-green-color">$20</div>
                </div>
                <div>
                  <p class="small-txt">
                    if more than B, add $5967 for each extra person
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="success-alert">
          Click Continue and you will be redirected to the National Verilier
          where you will need to submit proofs for verification. You will then
          be transfered back to automatically to compleyr your application.
        </div>
        <div class="d-flex justify-content-between mt-32">
          <router-link
            to="/ProfilePage?profileStep=3"
            class="btn-round btn-black-ghost"
            >Back</router-link
          >
          <router-link
            to="/ProfilePage?profileStep=5"
            class="btn-round btn-green"
            >Continue</router-link
          >
        </div>
      </div>
    </div>
  </section>

  <InternalServerError />
</template>

<script>
import InternalServerError from "../../helpers/Modals/InternalServerError.vue";

export default {
  components: {
    InternalServerError,
  },
  methods: {
    setOptionItemId(number) {
      this.optionIndex = number;
    },
  },
  data() {
    return {
      optionIndex: 0,
    };
  },
};
</script>

<style>
@import "../../../assets/styles/components/sections/Profile/04_EligibilitySection.css";
</style>
