<template>
  <section class="section section--bottom--space">
    <h3>Finance Profile</h3>
    <div class="row">
      <div class="col-md-6 col-sm-12 col-xs-12 mb-31">
        <div class="general-card general-finance-card">
          <div class="finance-card-title mb-0">My Cards</div>

          <div class="text-center">
            <img :src="NoCreditCard" alt="" />
            <div class="creditCard-text mb-18">No credit card defined</div>

            <button
              type="button"
              class="btn-round btn-green"
              v-on:click="openModal()"
            >
              Define new card
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-xs-12 mb-m-31">
        <div class="general-card general-finance-card">
          <div class="finance-card-title">Top up</div>

          <div class="creditCard-text">You have no credit cards defined</div>

          <div class="creditCard-text">Add a new credit card</div>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col-xs-12">
        <div class="general-card general-finance-card">
          <div class="finance-card-title">Renew plan</div>

          <div class="creditCard-text">You have no credit cards defined</div>

          <div class="creditCard-text">Add a new credit card</div>
        </div>
      </div>
    </div>
  </section>
  <DefineCardModal :showModal="this.showModal" />
</template>

<script>
import DefineCardModal from "../../helpers/Modals/DefineCardModal.vue";
// import FinanceCard from "../../../components/helpers/FinanceCard.vue";

export default {
  data() {
    return {
      showModal: false,
      NoCreditCard: require("../../../assets/images/general/NoCreditCard.svg"),
    };
  },
  components: {
    DefineCardModal,
    // FinanceCard,
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
  },
};
</script>

<style>
@import "../../../assets/styles/components/sections/Dashboard/DashobardFinanceSectionMyProfile.css";
</style>
