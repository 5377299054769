<template>
  <img
    :src="HamburgerLogo"
    alt=""
    class="float-right navbar-icon-hamburger"
    v-on:click="showNavbar = !showNavbar"
  />
  <div class="navbar-block" v-show="!showNavbar">
    <div class="position-relative">
      <div class="navbar-block--btn-sign-up-block">
        <button
          :class="[this.isUserLogin() ? 'd-none' : '']"
          v-on:click="logOut()"
          class="btn-sign-up"
        >
          Log out
        </button>
        <button
          :class="[this.isUserLogin() ? '' : 'd-none']"
          v-on:click="logIn()"
          class="btn-sign-up"
        >
          Log in
        </button>
      </div>
    </div>
    <div class="navbar-block-closeIcon">
      <img :src="iconClose" alt="" v-on:click="showNavbar = !showNavbar" />
    </div>
    <div>
      <ul class="navbar-block-menu">
        <li class="desktop-hidden">
          <a
            v-on:click="showNavbar = !showNavbar"
            to="http://peeringhub.mobi/PrePaidPage"
            class="navbar-block-menu-link"
          >
            Prepaid Plan
          </a>
        </li>
        <li class="desktop-hidden">
          <a
            v-on:click="showNavbar = !showNavbar"
            to="http://peeringhub.mobi/ACPPage"
            class="navbar-block-menu-link"
          >
            ACP Plan
          </a>
        </li>
        <li class="desktop-hidden">
          <a
            v-on:click="showNavbar = !showNavbar"
            to="http://peeringhub.mobi/OwnPlanPage"
            class="navbar-block-menu-link"
          >
            Make Your Plan
          </a>
        </li>
        <li class="desktop-hidden">
          <a
            v-on:click="showNavbar = !showNavbar"
            to="http://peeringhub.mobi/ActiveSimCardPage"
            class="navbar-block-menu-link"
          >
            Activate Now
          </a>
        </li>
        <li>
          <a
            v-on:click="showNavbar = !showNavbar"
            to="http://peeringhub.mobi/"
            class="navbar-block-menu-link"
          >
            About Us
          </a>
        </li>
        <li>
          <a
            v-on:click="showNavbar = !showNavbar"
            to="http://peeringhub.mobi/CheckoutPage"
            class="navbar-block-menu-link"
          >
            Features
          </a>
        </li>
        <li>
          <a
            v-on:click="showNavbar = !showNavbar"
            to="http://peeringhub.mobi/FrequentlyAskedQuestionsPage"
            class="navbar-block-menu-link"
          >
            How It Work
          </a>
        </li>
        <li>
          <a
            v-on:click="showNavbar = !showNavbar"
            href="http://peeringhub.mobi"
            class="navbar-block-menu-link"
          >
            Support
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div
    v-on:click="showNavbar = !showNavbar"
    class="position-absolute black-bg"
    v-show="!showNavbar"
  ></div>
</template>

<script>
import { getToken, destroyToken } from "../../common/token.service";
import { destroySubscriberId } from "../../common/subscriberId.service";

export default {
  data() {
    return {
      showNavbar: true,
      iconClose: require("../../assets/images/components/navbar/Cloes-icon.svg"),
      HamburgerLogo: require("../../assets/images/components/header/Hamburger-Black.svg"),
    };
  },
  methods: {
    isUserLogin() {
      return getToken() === null ? true : false;
    },
    logIn() {
      this.$router.push("/LoginPage");
    },
    logOut() {
      destroyToken();
      destroySubscriberId();

      this.showNavbar = !this.showNavbar;
      this.$router.push("/LoginPage");
    },
  },
};
</script>

<style>
@import "../../assets/styles/components/layouts/Navbar.css";
</style>
