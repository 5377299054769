<template>
  <section class="section-dashboard mt-132">
    <div class="container">
      <div class="d-flex justify-content-between">
        <div class="section-title">
          My <span class="main-green-color">Account</span>
        </div>
        <div v-if="isActiveIndex == 2" class="d-flex">
          <div class="mr-16">
            <button to="" class="btn-small-green" v-on:click="openModalTopUp()">
              Top Up
            </button>
          </div>
          <!-- TO DO: GET API DATA -->
          <!-- <div class="mt-3">Credit: XXXX 4858</div> -->
          <div>
            <Informer
              description="Use this top-up credit to pay international call"
            />
          </div>
        </div>
      </div>
      <DashboardTab isActiveIndex="2" />

      <DashobardSectionSIM />
    </div>
    <TopUpModal :showModal="this.showModalTopUp" />
  </section>
</template>

<script>
import DashobardSectionSIM from "../../../components/sections/Dashboard/02_DashobardSectionSIM.vue";
import DashboardTab from "../../helpers/DashboardTab.vue";

import {
  Administrator,
  Reseller,
  Subscriber,
} from "../../../store/users.type.js";
import TopUpModal from "../../helpers/Modals/TopUpModal.vue";
import Informer from "../../helpers/Informer.vue";

export default {
  components: {
    DashobardSectionSIM,
    TopUpModal,
    Informer,
    DashboardTab,
  },
  computed: {
    userRole: {
      get() {
        return this.$store.state.RoleUserStore.userRole;
      },
    },
  },
  methods: {
    openModalTopUp() {
      this.showModalTopUp = true;
    },
  },
  data() {
    return {
      showModalTopUp: false,

      Administrator: Administrator,
      Reseller: Reseller,
      Subscriber: Subscriber,
    };
  },
};
</script>

<style>
@import "../../../assets/styles/components/pages/Dashboard/DashboardPage.css";
@import "../../../assets/styles/components/pages/Landing/HomePage.css";
</style>
