<template>
  <section class="section section--bottom--space">
    <div>
      <div class="general-card">
        <div class="table-responsive">
          <table class="table table-sim">
            <thead>
              <tr>
                <td scope="col" class="fw-500">ESN</td>
                <td scope="col" class="fw-500">Activated On</td>
                <td scope="col" class="fw-500">Expired On</td>
                <td scope="col" class="fw-500">Plan Type</td>
                <td scope="col" class="fw-500">Text</td>
                <td scope="col" class="fw-500">Call</td>
                <td scope="col" class="fw-500">Auto renew</td>
                <td scope="col" class="fw-500">Status</td>
                <td scope="col" class="fw-500"></td>
              </tr>
            </thead>
            <tbody>
              <template v-for="(data, index) in SIMforSubscriber" :key="index">
                <tr>
                  <td
                    v-if="
                      data.simSubscription.planSubscription.plan.isActive ==
                      true
                    "
                  >
                    {{ data.esn }}
                  </td>
                  <td>{{ this.cleanDate(data.activatedAt) }}</td>
                  <td>{{ this.cleanDate(data.destroyedAt) }}</td>
                  <td>
                    {{ data.simSubscription.planSubscription.plan.name }}
                  </td>
                  <td>
                    {{ data.simSubscription.planSubscription.plan.mvnoPlanId }}
                  </td>
                  <td>
                    {{ data.simSubscription.planSubscription.plan.mvnoPlanId }}
                  </td>
                  <td>
                    <div class="d-flex">
                      <div class="form-check form-switch text-center mr-10">
                        <input
                          class="form-check-input custom-control-input"
                          v-on:click="clickAutoTopUp(data.simSubscription.id)"
                          type="checkbox"
                          role="switch"
                        />
                      </div>
                      {{
                        data.simSubscription.planSubscription.autoRenew === true
                          ? "Enabled"
                          : "Disabled"
                      }}
                      <div></div>
                    </div>
                  </td>
                  <td>
                    <div
                      v-if="
                        data.simSubscription.planSubscription.plan.isActive ==
                        true
                      "
                      class="table-sim-active"
                    >
                      Active
                    </div>
                    <div
                      v-if="
                        data.simSubscription.planSubscription.plan.isActive ==
                        false
                      "
                      class="table-sim-not-active"
                    >
                      Not Active
                    </div>
                  </td>
                  <td></td>
                  <td>
                    <button
                      to=""
                      class="btn-small btn-black-ghost"
                      v-on:click="openModalRenew(data.id)"
                    >
                      Renew
                    </button>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
  <RenewModal :id="this.id" :showModal="this.showModalRenew" />
</template>

<script>
import RenewModal from "../../helpers/Modals/RenewModal.vue";
import SIMService from "../../../common/services/simService";

export default {
  data() {
    return {
      showModalRenew: false,
      SIMforSubscriber: {},
    };
  },
  mounted() {
    this.fetchSIM();
  },
  components: {
    RenewModal,
  },
  methods: {
    async clickAutoTopUp(id) {
      await SIMService.postAutoRenewSIMforSubscriber(id);
      await this.fetchSIM();
    },
    cleanDate(date) {
      if (date === null || date === undefined) return;
      return date.slice(0, -5).replace("T", " ");
    },
    fetchSIM() {
      this.$store.dispatch("addSIMforSubscriber");
      this.SIMforSubscriber =
        this.$store.state.DashobardSectionSIMStore.SIMforSubscriber;
    },
    openModalRenew(id) {
      this.id = id;
      this.showModalRenew = true;
    },
  },
};
</script>

<style>
@import "../../../assets/styles/components/sections/Dashboard/02_DashobardSectionSIM.css";
</style>
