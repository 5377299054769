<template>
  <b :class="[this.currentSMS == 'unlimited' ? 'd-none' : null]">{{
    currentSMS
  }}</b>
  <span :class="[this.currentSMS == 'unlimited' ? 'd-none' : null]"
    >/{{ maxValue }}</span
  >
  <div
    :class="[this.currentSMS == 'unlimited' ? 'd-none' : 'customProgressBar']"
  >
    <div
      :class="[
        this.currentSMS == 'unlimited'
          ? 'd-none'
          : progressBarChecker(currentSMS),
      ]"
      :style="{
        width:
          (parseInt(this.currentSMS) * 100) / parseInt(this.maxValue) + 'px',
      }"
    ></div>
  </div>
  <div class="position-relative">
    <div
      :class="[
        this.currentSMS == 'unlimited'
          ? 'customProgressBar-unlimited'
          : 'd-none',
      ]"
    ></div>
  </div>
</template>

<script>
import SIMService from "../../common/services/simService";

export default {
  props: ["simId", "maxValue", "currentValue"],
  methods: {
    progressBarChecker(currentValue) {
      if (parseInt(currentValue) > 50) {
        return "customProgressBar-red";
      } else {
        return "customProgressBar-green";
      }
    },
  },
  data() {
    return {
      currentSMS: 0,
    };
  },
  mounted() {
    SIMService.getSubscriberSIMServiceInfo(this.simId).then(
      (response) =>
        (this.currentSMS = response.data.BALANCEDETAIL.TOTALBALANCE.TEXT)
    );
  },
};
</script>

<style>
@import "./../../assets/styles/helpers/progressBar.css";
</style>
