<template>
  <b :class="[this.currentData == 'unlimited' ? 'd-none' : null]">{{
    Math.round(currentData / 1024 / 1024)
  }}</b>
  <span :class="[this.currentData == 'unlimited' ? 'd-none' : null]"
    >/{{ maxValue }}</span
  >
  <div
    :class="[this.currentData == 'unlimited' ? 'd-none' : 'customProgressBar']"
  >
    <div
      :class="[
        this.currentData == 'unlimited'
          ? 'd-none'
          : progressBarChecker(Math.round(currentData / 1024 / 1024)),
      ]"
      :style="{
        width:
          (parseInt(Math.round(currentData / 1024 / 1024)) * 100) /
            parseInt(this.maxValue) +
          'px',
      }"
    ></div>
  </div>
  <div class="position-relative">
    <div
      :class="[
        this.currentData == 'unlimited'
          ? 'customProgressBar-unlimited'
          : 'd-none',
      ]"
    ></div>
  </div>
</template>

<script>
import SIMService from "../../common/services/simService";

export default {
  props: ["simId", "maxValue", "currentValue"],
  methods: {
    progressBarChecker(currentValue) {
      const percents = Math.round(currentValue / 1024 / 1024) * 100;
      if (parseInt(percents) > 50) {
        return "customProgressBar-red";
      } else {
        return "customProgressBar-green";
      }
    },
  },
  data() {
    return {
      currentData: 0,
    };
  },
  mounted() {
    SIMService.getSubscriberSIMServiceInfo(this.simId).then(
      (response) =>
        (this.currentData = response.data.BALANCEDETAIL.TOTALBALANCE.DATA)
    );
  },
};
</script>

<style>
@import "./../../assets/styles/helpers/progressBar.css";
</style>
