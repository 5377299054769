<template>
  <section class="new-dashboard-bg">
    <div class="row pr-32">
      <div class="col-md-3">
        <NewDashboardNavbar />
      </div>
      <div class="col-md-9">
        <div class="d-flex justify-content-between">
          <Search />
          <div class="d-flex">
            <Notification />
            <Avatar />
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <div>
            <DashboardTitlte title="Fulfilment" />
          </div>
          <div class="mt-53">
            <div class="d-flex">
              <div class="btn-black btn-black--ghost mr-16">
                Deliver Selected
              </div>
              <div class="btn-black btn-black--ghost mr-16">Ship Selected</div>

              <router-link
                to="/NewCreateFulfilmentPage"
                class="btn-black btn-black--plus"
                >Create Fulfilment</router-link
              >
            </div>
          </div>
        </div>

        <div class="dashboard-content mb-16 backdown-24">
          <table class="table table-bordered dashboard-content-table">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Subscriber</th>
                <th scope="col">SIM ESN</th>
                <th scope="col">Created on</th>
                <th scope="col">status</th>
                <th scope="col">Reseller</th>
              </tr>
            </thead>
            <tbody>
              <template
                :key="index"
                v-for="(simSubscription, index) in SIMForAdministrator"
              >
                <tr>
                  <th scope="row">Dec 18, 2022</th>
                  <td>877</td>
                  <td>9374</td>
                  <td>8013</td>
                  <td>5948</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Search from "../../sections/NewDashboard/Search.vue";
import NewDashboardNavbar from "../../../components/layouts/NewDashboardNavbar.vue";
import DashboardTitlte from "../../../components/helpers/DashboardTitle.vue";
import Notification from "../../../components/sections/NewDashboard/Notification.vue";
import Avatar from "../../../components/sections/NewDashboard/Avatar.vue";

export default {
  components: {
    Search,
    NewDashboardNavbar,
    DashboardTitlte,
    Notification,
    Avatar,
  },
  data() {
    return {};
  },
  mounted() {
    this.setDashboardTab();
  },
  methods: {
    setDashboardTab() {
      this.$store.dispatch("addDashboardTab", 7);
    },
  },
};
</script>

<style>
@import "../../../assets/styles/components/pages/NewDashboard/NewDashboard.css";
</style>
