<template>
  <footer class="footer">
    <div class="container container-1170 text-white">
      <div class="row">
        <div class="col-md-3 col-lg-3">
          <router-link to="/">
            <img :src="footerLogo" alt="" class="mb-30" />
          </router-link>
          <p>
            Peeringhub is a MVNO of T-mobile. You get exactly the same services
            and network as T-Mobile except you are paying much much less with
            us.
          </p>
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <div class="footer-menu-title">Quick Links</div>
          <ul class="footer-menu">
            <li>
              <router-link to="/PrePaidPage" class="footer-menu-link">
                Prepaid
              </router-link>
            </li>
            <li>
              <router-link to="/ACPPage" class="footer-menu-link">
                ACP
              </router-link>
            </li>
            <li>
              <router-link to="/OwnPlanPage" class="footer-menu-link">
                Build Your Plan
              </router-link>
            </li>
            <li>
              <router-link to="/InternationalRatePage" class="footer-menu-link">
                International
              </router-link>
            </li>
            <li>
              <router-link to="#" class="footer-menu-link">
                Tablet
              </router-link>
            </li>
            <li>
              <router-link to="#" class="footer-menu-link">
                Coverage
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-6 col-md-4 col-lg-3">
          <div class="footer-menu-title">Support</div>
          <ul class="footer-menu">
            <li>
              <router-link to="#" class="footer-menu-link">
                About Us
              </router-link>
            </li>
            <li>
              <router-link to="#" class="footer-menu-link">
                Contact Us
              </router-link>
            </li>
            <li>
              <a href="http://support.peeringhub.mobi" class="footer-menu-link">
                Get Support
              </a>
            </li>
          </ul>
        </div>
        <div class="col-12 col-md-4 col-lg-3">
          <div class="footer-menu-title">Contact</div>
          <div class="footer-contact-block">
            <img :src="footerIconEmail" alt="" />
            support@peeringhub.io
          </div>
          <div class="footer-contact-block">
            <img :src="footerIconPhone" alt="" />
            +1 (838) 217-8999
          </div>
          <div class="footer-contact-block">
            <img :src="footerIconSupport" alt="" />
            http://support.peeringhub.mobi
          </div>
        </div>
      </div>
    </div>
    <div class="footer-copyright">
      <div class="container text-white">
        <p>Copyright © 2023 Peeringhub Mobi. All Rights Reserved.</p>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer",
  data() {
    return {
      footerLogo: require("../../assets/images/components/footer/Footer-Logo.svg"),
      footerIconEmail: require("../../assets/images/components/footer/Footer-Icon-Email.svg"),
      footerIconPhone: require("../../assets/images/components/footer/Footer-Icon-Phone.svg"),
      footerIconSupport: require("../../assets/images/components/footer/Footer-Icon-Support.svg"),
    };
  },
};
</script>
<style>
@import "../../assets/styles/components/layouts/Footer.css";

@import "../../assets/styles/tablet.css";
@import "../../assets/styles/mobile.css";
@import "../../assets/styles/customSize.css";
</style>
