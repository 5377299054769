<template>
  <section class="section section--bottom--space">
    <div>
      <div class="general-card">
        <div class="table-responsive">
          <table class="table table-sim">
            <thead>
              <tr>
                <td scope="col" class="fw-500">Number</td>
                <td scope="col" class="fw-500">Country</td>
                <td scope="col" class="fw-500">Type</td>
                <td scope="col" class="fw-500">Minute</td>
                <td scope="col" class="fw-500">Rate</td>
                <td scope="col" class="fw-500">Cost</td>
              </tr>
            </thead>
            <tbody>
              <template :key="index" v-for="(data, index) in callLogs">
                <tr>
                  <td>{{ data.destinationId }}</td>
                  <td>
                    {{ data.destinationClass }}
                  </td>
                  <td></td>
                  <td>
                    {{ data.usageType }}
                  </td>
                  <td>{{ data.units }}</td>
                  <td>{{ data.rateClassUnitPrice }}</td>
                  <td>{{ data.rateClassCalculatedPrice }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CallLogsService from "../../../common/services/callLogsService";

export default {
  data() {
    return {
      callLogs: "",
    };
  },
  components: {},
  mounted() {
    this.fetchSIM();
  },
  methods: {
    async fetchSIM() {
      await this.$store.dispatch("addSIMforSubscriber");
      this.SIMforSubscriber =
        this.$store.state.DashobardSectionSIMStore.SIMforSubscriber;
      await this.getAllLogsdata(this.SIMforSubscriber);
    },
    async getAllLogsdata(data) {
      const simData = [];
      console.log(data);
      data.forEach(async function (item) {
        const data = await CallLogsService.postSubscriberSimCallLogById(
          item.id
        );

        simData.push(...data.data);
      });
      await this.$store.dispatch("addCallLogs", simData);
      this.callLogs = this.$store.state.DashobardCallLogsStore.callLogs;
    },
  },
};
</script>

<style>
@import "../../../assets/styles/components/sections/Dashboard/07_DashboardCallLog.css";
</style>
