<template>
  <section class="section-dashboard mt-132">
    <div class="container">
      <div class="section-title">
        My <span class="main-green-color">Account</span>
      </div>
      <DashboardTab isActiveIndex="3" />

      <DashobardSectionPaymentMethod />
    </div>
  </section>
</template>

<script>
import DashobardSectionPaymentMethod from "../../sections/Dashboard/03_DashobardSectionPaymentMethod.vue";
import DashboardTab from "../../helpers/DashboardTab.vue";

import {
  Administrator,
  Reseller,
  Subscriber,
} from "../../../store/users.type.js";

export default {
  components: {
    DashobardSectionPaymentMethod,
    DashboardTab,
  },
  computed: {
    userRole: {
      get() {
        return this.$store.state.RoleUserStore.userRole;
      },
    },
  },
  methods: {},
  data() {
    return {
      Administrator: Administrator,
      Reseller: Reseller,
      Subscriber: Subscriber,
    };
  },
};
</script>

<style>
@import "../../../assets/styles/components/pages/Dashboard/DashboardPage.css";
@import "../../../assets/styles/components/pages/Landing/HomePage.css";
</style>
