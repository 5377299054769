<template>
  <section class="pt-100 section section-main pb-10" :class="[backgroundurl]">
    <div class="container section-main-container">
      <div class="row mt-72">
        <div class="col-md-6 col-sm-6">
          <div class="main-title-block">
            <span class="main-title-block-bigNumber">01</span><span>/05</span>
            <div class="main-title-block-title">5G for Free</div>
            <p class="mt-23">
              Enjoy the fastest nationwide 5G network <br />from T-mobile
            </p>
            <button class="btn-round btn-round--ghost btn-round--main">
              Read More
            </button>
            <div class="main-title-block-slider mt-150">
              <span
                :class="[
                  isActiveIndex == 1 ? 'main-title-block-slider-active' : '',
                ]"
                @click="setActiveItemId('1')"
                >01</span
              >
              <span
                :class="[
                  isActiveIndex == 2 ? 'main-title-block-slider-active' : '',
                ]"
                @click="setActiveItemId('2')"
                >02</span
              >
              <span
                :class="[
                  isActiveIndex == 3 ? 'main-title-block-slider-active' : '',
                ]"
                @click="setActiveItemId('3')"
                >03</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-1 d-sm-none d-md-block"></div>
        <div class="col-md-5 col-sm-6">
          <div class="login-register-block">
            <div class="login-register-block-radios form-group mb-31">
              <input
                type="radio"
                class="btn-check"
                name="options-outlined"
                id="success-outlined"
                autocomplete="off"
                checked
              />
              <label
                @click="setActiveButton(true)"
                class="btn btn-gray w-50 text-center"
                for="success-outlined"
                >Sign In</label
              >
              <input
                type="radio"
                class="btn-check"
                name="options-outlined"
                id="dark-outlined"
                autocomplete="off"
              />
              <label
                @click="setActiveButton(false)"
                class="btn btn-gray w-50 text-center"
                for="dark-outlined"
                >Sign up</label
              >
            </div>
            <div>
              <div :class="[showButton == false ? 'd-none' : '']">
                <div class="form-group">
                  <label>Email</label>
                  <input
                    placeholder="Enter email"
                    type="text"
                    class="input-form w-100"
                    v-model="email"
                  />
                </div>
                <div class="form-group">
                  <label>Password</label>
                  <input
                    placeholder="Enter password"
                    type="password"
                    class="input-form w-100"
                    v-model="password"
                  />
                </div>
              </div>
              <div :class="[showButton == true ? 'd-none' : '']">
                <div class="form-group">
                  <label>First Name</label>
                  <input
                    placeholder="John"
                    type="text"
                    class="input-form w-100"
                    v-model="firstName"
                  />
                </div>
                <div class="form-group">
                  <label>Last Name</label>
                  <input
                    placeholder="Doe"
                    type="text"
                    class="input-form w-100"
                    v-model="lastName"
                  />
                </div>
              </div>
              <div
                :class="[showButton == false ? 'd-none' : 'text-error mt-18']"
              >
                {{ this.errorLogin }}
              </div>
              <div class="mt-18">
                <div class="form-group text-center">
                  <button
                    v-on:click="logIn()"
                    :class="[
                      showButton == false
                        ? 'd-none'
                        : 'd-block btn-round btn-round--inverse w-100 mb-0',
                    ]"
                  >
                    Log in
                  </button>
                  <button
                    v-on:click="Register()"
                    :class="[
                      showButton == true
                        ? 'd-none'
                        : 'd-block btn-round btn-round--inverse w-100 mb-0',
                    ]"
                  >
                    Continue
                  </button>
                </div>
                <div class="text-center mt-12">
                  <router-link class="forgot-link" to="/ForgotPasswordPage"
                    >Forgot Password</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="owl-arrow">
      <img
        :src="owlLeftArrow"
        alt=""
        class="owl-arrow-right"
        @click="changeCurretnItem(this.isActiveIndex, 'plus')"
      />
      <img
        :src="owlRightArrow"
        alt=""
        class="owl-arrow-left"
        @click="changeCurretnItem(this.isActiveIndex, 'minus')"
      />
    </div>
    <div :class="[isActiveIndex == 0 ? 'section-main-bg' : '']"></div>
    <div
      :class="[
        isActiveIndex == 1 ? 'section-main-bg animate__bounceInLeft' : '',
      ]"
    ></div>
    <div
      :class="[
        isActiveIndex == 2 ? 'section-main-bg-2 animate__bounceInLeft' : '',
      ]"
    ></div>
    <div
      :class="[
        isActiveIndex == 3 ? 'section-main-bg-3 animate__bounceInLeft' : '',
      ]"
    ></div>
  </section>
</template>

<script>
import LoginService from "../../../common/services/loginService";
import { saveToken, destroyToken } from "../../../common/token.service";
import { Administrator, Reseller, Subscriber } from "../../../store/users.type";
import { saveSubscriberId } from "../../../common/subscriberId.service";

export default {
  data() {
    return {
      firstName: sessionStorage.getItem("firstName"),
      lastName: sessionStorage.getItem("lastName"),
      email: "",
      password: "",

      errorLogin: "",

      showButton: true,
      isActiveIndex: 0,
      backgroundurl: "section-main",

      owlRightArrow: require("../../../assets/images/sections/MainSection/owl-left.svg"),
      owlLeftArrow: require("../../../assets/images/sections/MainSection/owl-right.svg"),
    };
  },
  mounted() {
    this.checkURLforLoginData();
  },
  methods: {
    setUseerRoleSubscriber() {
      this.$store.dispatch("addUserRoleSubscriber", Subscriber);
    },
    setUseerRoleReseller() {
      this.$store.dispatch("addUserRoleReseller", Reseller);
    },
    setUseerRoleAdministrator() {
      this.$store.dispatch("addUserRoleAdministrator", Administrator);
    },
    checkURLforLoginData() {
      destroyToken();

      if (this.$route.query.token) {
        if (this.$route.query.role == Administrator) {
          this.setUseerRoleAdministrator();
          saveToken(this.$route.query.token);
          this.$router.push("NewDashboardPage");
          return;
        }
        if (this.$route.query.role == Reseller) {
          this.setUseerRoleReseller();
          saveToken(this.$route.query.token);
          this.$router.push("NewDashboardPage");
          return;
        }
        if (this.$route.query.role == Subscriber) {
          this.setUseerRoleSubscriber();
          saveToken(this.$route.query.token);
          this.$router.push("DashboardPage");
          return;
        }
      }
    },
    Register() {
      sessionStorage.setItem("firstName", this.firstName);
      sessionStorage.setItem("lastName", this.lastName);
      this.$router.push("RegistrationPage");
    },
    async logIn() {
      const subscriberData = await LoginService.postSubscriberLogin({
        username: this.email,
        password: this.password,
      });

      if (subscriberData.data.authToken) {
        saveToken(subscriberData.data.authToken);
        saveSubscriberId(subscriberData.data.subscriber.id);
        this.setUseerRoleSubscriber();
        this.$router.push("DashboardPage");
        return;
      }

      const resellerData = await LoginService.postReesellerLogin({
        username: this.email,
        password: this.password,
      });

      if (resellerData.data.authToken) {
        saveToken(resellerData.data.authToken);
        this.setUseerRoleReseller();
        this.$router.push("NewDashboardPage");
        return;
      }

      const administratorData = await LoginService.postAdministratorLogin({
        username: this.email,
        password: this.password,
      });

      if (administratorData.data.authToken) {
        saveToken(administratorData.data.authToken);
        this.setUseerRoleAdministrator();
        this.$router.push("NewDashboardPage");
        return;
      }

      this.errorLogin = "Error Login";
    },
    setActiveItemId(number) {
      this.isActiveIndex = number;
      this.getBackgroundUrl(number);
    },
    setActiveButton(value) {
      this.showButton = value;
    },
    changeCurretnItem(number, operation) {
      operation == "plus" ? number++ : number--;

      if (number == 1) this.setActiveItemId("1");
      if (number == 2) this.setActiveItemId("2");
      if (number == 3) this.setActiveItemId("3");
    },
    getBackgroundUrl(number) {
      switch (number) {
        case "1":
          this.backgroundurl = "section-main";
          break;
        case "2":
          this.backgroundurl = "section-main-2";
          break;
        case "3":
          this.backgroundurl = "section-main-3";
          break;
      }
    },
  },
};
</script>

<style>
@import "../../../assets/styles/components/sections/Landing/MainSection.css";
</style>
