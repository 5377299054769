<template>
  <footer class="footer-1pSim">
    <div class="container container-1170 text-white">
      <div class="row">
        <div class="col-2 col-md-2 col-lg-2">
          <router-link to="/">
            <img :src="footerLogo" alt="" class="mb-30 footerLogo-1pSim" />
          </router-link>
          <p>
            Peeringhub is a MVNO of T-mobile. You get exactly the same services
            and network as T-Mobile except you are paying much much less with
            us.
          </p>
        </div>
        <div class="col-2 col-md-2 col-lg-2">
          <div class="footer-menu-title-1pSim">Plans</div>
          <ul class="footer-menu-1pSim">
            <li>
              <router-link to="#" class="footer-menu-link-1pSim">
                Paygo Plan
              </router-link>
            </li>
            <li>
              <router-link to="#" class="footer-menu-link-1pSim">
                Tourist Plan
              </router-link>
            </li>
            <li>
              <router-link to="#" class="footer-menu-link-1pSim">
                Value Plan
              </router-link>
            </li>
            <li>
              <router-link
                to="/InternationalRatePage"
                class="footer-menu-link-1pSim"
              >
                Imigrant Plan
              </router-link>
            </li>
            <li>
              <router-link to="#" class="footer-menu-link-1pSim">
                Travaler Plan
              </router-link>
            </li>
            <li>
              <router-link to="#" class="footer-menu-link-1pSim">
                Roaming Plan
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-2 col-md-2 col-lg-2">
          <div class="footer-menu-title-1pSim">Company</div>
          <ul class="footer-menu-1pSim">
            <li>
              <router-link to="#" class="footer-menu-link-1pSim">
                Active
              </router-link>
            </li>
            <li>
              <router-link to="#" class="footer-menu-link-1pSim">
                Intenational rate
              </router-link>
            </li>
            <li>
              <router-link to="#" class="footer-menu-link-1pSim">
                Shipping fee
              </router-link>
            </li>
            <li>
              <router-link to="#" class="footer-menu-link-1pSim">
                About
              </router-link>
            </li>
            <li>
              <router-link to="#" class="footer-menu-link-1pSim">
                Support
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-2 col-md-2 col-lg-2">
          <div class="footer-menu-title-1pSim">Contact</div>
          <ul class="footer-menu-1pSim">
            <li>
              <router-link to="#" class="footer-menu-link-active-1pSim">
                (0)800-205-205
              </router-link>
            </li>
            <li>
              <router-link to="#" class="footer-menu-link-1pSim">
                info@mail.com
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-2 col-md-2 col-lg-2">
          <div class="footer-menu-title-1pSim">Support</div>
          <ul class="footer-menu-1pSim">
            <li>
              <router-link to="#" class="footer-menu-link-active-1pSim">
                (0)800-205-205
              </router-link>
            </li>
            <li>
              <router-link to="#" class="footer-menu-link-1pSim">
                info@mail.com
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-2 col-md-2 col-lg-2">
          <router-link to="#" class="button-1pSim-blue mb-30">
            View plans
          </router-link>
          <router-link to="#" class="button-1pSim-white">
            Contact us
          </router-link>
        </div>
      </div>
    </div>
    <div class="footer-copyright-1pSim container footer-copyright-1pSim-line">
      <div>
        <p>© 2023 1PSIM. All rights reserved.</p>
      </div>
      <div>
        <a href="#">
          <img :src="scorllUp" alt="" />
        </a>
      </div>
    </div>
    <div class="footer-1pSim-bg"></div>
  </footer>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer1Sim",
  data() {
    return {
      footerLogo: require("../images/logo.svg"),
      scorllUp: require("../images/scrollUp.svg"),
    };
  },
};
</script>
<style></style>
