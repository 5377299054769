import ApiService from "../api.service";

const OrderStatusService = {
  get() {
    return ApiService.get(
      `${process.env.VUE_APP_API_LINK}/subscriber/fulfillment`
    );
  },
  post(payload) {
    return ApiService.post(`${process.env.VUE_APP_API_LINK}/`, {
      comment: { body: payload },
    });
  },
};

export default OrderStatusService;
