<template>
  <section class="section section-contact">
    <div class="container container-1170">
      <p>
        <b>Need Help?</b> Use this information and contact Peeringhub Mobi
        Support Center
      </p>
      <div class="footer-contact-block gray-color">
        <img :src="footerIconEmail" alt="" />
        support@peeringhub.io
      </div>
      <div class="footer-contact-block gray-color">
        <img :src="footerIconPhone" alt="" />
        +1 (838) 217-8999
      </div>
      <div class="mt-8">
        <p class="main-gray-color">
          Support Portal: http://support.peeringhub.mobi
        </p>
      </div>
      <div class="mt-8">
        <p class="main-gray-color">Monday - Friday: 8:00 a.m. - 8:00 p.m.</p>
      </div>
      <div class="mt-8">
        <p class="main-gray-color">Saturday: 10:00 a.m. - 7:00 p.m.</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      footerIconEmail: require("../../../assets/images/components/footer/Footer-Icon-Email.svg"),
      footerIconPhone: require("../../../assets/images/components/footer/Footer-Icon-Phone.svg"),
    };
  },
};
</script>

<style>
@import "../../../assets/styles/components/sections/Profile/ContactSection.css";
</style>
