import OrderService from "../../common/services/orderService";

import { SET_Orders } from "../actions.type";

export const state = {
  orders: {},
};

export const actions = {
  async addOrders(context, payload) {
    const data = await OrderService.get(payload);
    return context.commit(SET_Orders, data.data);
  },
};

export const mutations = {
  SET_Orders(state, payload) {
    state.orders = payload;
  },
};

const getters = {
  getOrders: function (state) {
    return `${state.orders}`;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
