import ApiService from "../api.service";

const ProfileService = {
  getSubscriberProfile() {
    return ApiService.get(`${process.env.VUE_APP_API_LINK}/subscriber/profile`);
  },
  patchSubscriberProfile(payload) {
    return ApiService.patch(
      `${process.env.VUE_APP_API_LINK}/subscriber/profile`,
      payload
    );
  },
};

export default ProfileService;
