const DOMAIN = "1psim.com";
// const DOMAIN = "localhost:8080";

const isThis1PsimDomain = () => {
  return window.location.host.includes(DOMAIN);
};

export const detecTheme = () => {
  if (isThis1PsimDomain()) {
    return {
      themePeer: false,
      theme1psim: true,
    };
  } else {
    return {
      themePeer: true,
      theme1psim: false,
    };
  }
};
