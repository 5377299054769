<template>
  <section class="new-dashboard-bg">
    <div class="row pr-32">
      <div class="col-md-3">
        <NewDashboardNavbar />
      </div>
      <div class="col-md-9">
        <div class="d-flex justify-content-between">
          <Search />
          <div class="d-flex">
            <Notification />
            <Avatar />
          </div>
        </div>

        <DashboardTitlte title="Usage Report" />

        <div class="dashboard-content mb-16"></div>
      </div>
    </div>
  </section>
</template>

<script>
import Search from "../../sections/NewDashboard/Search.vue";
import NewDashboardNavbar from "../../layouts/NewDashboardNavbar.vue";
import DashboardTitlte from "../../helpers/DashboardTitle.vue";
import Notification from "../../sections/NewDashboard/Notification.vue";
import Avatar from "../../sections/NewDashboard/Avatar.vue";

export default {
  components: {
    Search,
    NewDashboardNavbar,
    DashboardTitlte,
    Notification,
    Avatar,
  },
  mounted() {
    this.setDashboardTab();
  },
  methods: {
    setDashboardTab() {
      this.$store.dispatch("addDashboardTab", 9);
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
@import "../../../assets/styles/components/pages/NewDashboard/NewDashboard.css";
</style>
