<template>
  <header class="header">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-6 col-xs-6 col-6">
          <router-link to="/">
            <img :src="iconLogo" alt="" />
          </router-link>
        </div>
        <div class="col-md-8 d-none d-md-block">
          <ul class="header-menu">
            <li>
              <a
                href="https://peeringhub.mobi/#/PrePaidPage"
                class="header-menu-link"
              >
                Prepaid Plan
              </a>
            </li>
            <li>
              <a
                href="https://peeringhub.mobi/#/ACPPage"
                class="header-menu-link"
              >
                ACP Plan
              </a>
            </li>
            <li>
              <a
                to="https://peeringhub.mobi/#/OwnPlanPage"
                class="header-menu-link"
              >
                Make Your Plan
              </a>
            </li>
            <li>
              <a
                href="https://peeringhub.mobi/#/ActiveSimCardPage"
                class="header-menu-link"
              >
                Activate now
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-1 col-sm-6 col-xs-6 col-6">
          <NavBar />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import NavBar from "../../components/layouts/NavBar.vue";

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      iconLogo: require("../../assets/images/components/header/Peering-Color-Logo.svg"),
    };
  },
};
</script>

<style>
@import "../../assets/styles/components/layouts/Header.css";
</style>
