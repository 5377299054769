<template>
  <section>
    <div class="subtitle mt-24 mb-24">
      {{ title }}
    </div>
  </section>
</template>

<script>
export default {
  props: ["title"],
  components: {},
  data() {
    return {};
  },
};
</script>

<style>
@import "../../assets/styles/helpers/dashboardTitle.css";
</style>
