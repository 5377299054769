<template>
  <section class="section-dashboard mt-132">
    <div class="container">
      <div class="section-title">
        My <span class="main-green-color">Account</span>
      </div>
      <DashboardTab isActiveIndex="5" />
      <div class="w-248 m-auto">
        <div
          class="login-register-block-radios form-group mb-31 my-profile-switcher"
        >
          <input
            type="radio"
            class="btn-check"
            name="options-outlined"
            id="success-outlined"
            autocomplete="off"
            checked
          />
          <label
            @click="setActiveButton(true)"
            class="btn btn-gray w-50 text-center"
            for="success-outlined"
            >My profile</label
          >
          <input
            type="radio"
            class="btn-check"
            name="options-outlined"
            id="dark-outlined"
            autocomplete="off"
          />
          <label
            @click="setActiveButton(false)"
            class="btn btn-gray w-50 text-center"
            for="dark-outlined"
            >Finance</label
          >
        </div>
      </div>

      <div :class="[showButton == true ? '' : 'd-none']">
        <DashobardSectionMyProfile />
      </div>

      <div :class="[showButton == true ? 'd-none' : '']">
        <DashobardFinanceSectionMyProfile />
      </div>
    </div>
  </section>
</template>

<script>
import DashobardSectionMyProfile from "../../sections/Dashboard/05_DashobardSectionMyProfile.vue";
import DashobardFinanceSectionMyProfile from "../../sections/Dashboard/DashobardFinanceSectionMyProfile.vue";
import DashboardTab from "../../helpers/DashboardTab.vue";

import {
  Administrator,
  Reseller,
  Subscriber,
} from "../../../store/users.type.js";

export default {
  components: {
    DashobardFinanceSectionMyProfile,
    DashobardSectionMyProfile,
    DashboardTab,
  },
  methods: {
    setActiveButton(value) {
      this.showButton = value;
    },
  },
  computed: {
    userRole: {
      get() {
        return this.$store.state.RoleUserStore.userRole;
      },
    },
  },
  data() {
    return {
      Administrator: Administrator,
      Reseller: Reseller,
      Subscriber: Subscriber,

      showButton: this.$route.query.tab || true,
    };
  },
};
</script>

<style>
@import "../../../assets/styles/components/pages/Dashboard/DashobardMyProfilePage.css";
</style>
