<template>
  <section class="section section-profile">
    <div>
      <p class="mb-32">
        You recently applied for the Lifeline benefit with a phone/internet
        company. Lifeline is a federal program that lowers the monthly cost of
        phone and internet for qualified consumers.
      </p>
      <p>
        We need some more information from you to complete the Lifeline
        application process.
      </p>
      <div class="general-card">
        <div class="mb-12">Name: <b>N/A</b></div>
        <div>Application ID: <b>N/A</b></div>
      </div>
      <div class="success-alert mt-32">
        By clicking *Next* to continue, I accept the
        <a>Terms and Conditions</a> of the Peeringhub Mobi
      </div>
      <div class="d-flex justify-content-between mt-32">
        <router-link
          to="/ProfilePage?profileStep=4"
          class="btn-round btn-black-ghost"
          >Back</router-link
        >
        <router-link to="/DashboardPage" class="btn-round btn-green"
          >Continue</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
};
</script>
