import {
  SET_USERRoleSubscriber,
  SET_USERRoleReseller,
  SET_USERRoleAdministrator,
} from "../actions.type";

export const state = {
  userRole: "",
};

export const actions = {
  addUserRoleSubscriber(context, payload) {
    return context.commit(SET_USERRoleSubscriber, payload);
  },
  addUserRoleReseller(context, payload) {
    return context.commit(SET_USERRoleReseller, payload);
  },
  addUserRoleAdministrator(context, payload) {
    return context.commit(SET_USERRoleAdministrator, payload);
  },
};

export const mutations = {
  SET_USERRoleSubscriber(state, payload) {
    state.userRole = payload;
  },
  SET_USERRoleReseller(state, payload) {
    state.userRole = payload;
  },
  SET_USERRoleAdministrator(state, payload) {
    state.userRole = payload;
  },
};

const getters = {
  getUserRole: function (state) {
    return `${state.userRole}`;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
