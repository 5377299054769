<template>
  <section class="section section-profile">
    <div>
      <h3>Household information</h3>
      <div class="general-card">
        <div>
          <div class="mb-24">
            <label
              >1. Do you live with another adult?
              <span class="label-required">*</span></label
            >
            <div class="d-flex">
              <div class="mr-16">
                <input
                  class="inputRadio"
                  type="radio"
                  name="optionAdult"
                  value="1"
                />
                Yes
              </div>
              <div>
                <input
                  class="inputRadio"
                  type="radio"
                  name="optionAdult"
                  value="2"
                />
                No
              </div>
            </div>
          </div>
          <div class="mb-24">
            <label
              >2. Do they receive APC/Lifeline Services?
              <span class="label-required">*</span></label
            >
            <div class="d-flex">
              <div class="mr-16">
                <input
                  class="inputRadio"
                  type="radio"
                  name="optionLifeline"
                  value="1"
                />
                Yes
              </div>
              <div>
                <input
                  class="inputRadio"
                  type="radio"
                  name="optionLifeline"
                  value="2"
                />
                No
              </div>
            </div>
          </div>
          <p>
            Please enter your initials below confirming you understand the
            one-per-household rule and consent to transfer or enroll your
            Lifeline and or ACP benefit with AirTalk Wireless
          </p>
          <p>
            I understand that the one-per-household limit is a Federal
            Communications Commission (FCC) rule and I will lose my Lifeline/ACP
            benefit if I break this rule.
          </p>
          <div class="mb-24">
            <input type="text" placeholder="MK" class="w-95" />
          </div>
          <p>
            I consent to apply and/or transfer my ACP Benefits to AirTalk
            Wireless and transition to Lifeline only services when the ACP
            Benefits ends. I understand I am under no obligation to purchase the
            unlimited service and will receive the free Lifeline service, if I
            qualify, after the ACP Benefits program ends.
          </p>
          <div>
            <input type="text" placeholder="MK" class="w-95" />
          </div>
          <div class="d-flex justify-content-between mt-32">
            <router-link
              to="/ProfilePage?profileStep=2"
              class="btn-round btn-black-ghost"
              >Back</router-link
            >
            <router-link
              to="/ProfilePage?profileStep=4"
              class="btn-round btn-green"
              >Continue</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>
