import simService from "../../common/services/simService";
import {
  SET_SIMForAdministrator,
  SET_SIMforSubscriber,
  SET_SIMforReseller,
} from "../actions.type";

export const state = {
  SIMForAdministrator: {},
  SIMforSubscriber: {},
  SIMforReseller: {},
};

export const actions = {
  async addSIMForAdministrator(context) {
    const data = await simService.getSIMforAdministrator();
    return context.commit(SET_SIMForAdministrator, data.data);
  },
  async addSIMforSubscriber(context) {
    const data = await simService.getSIMforSubscriber();
    return context.commit(SET_SIMforSubscriber, data.data);
  },
  async addSIMforReseller(context, payload) {
    const data = await simService.get(payload);
    return context.commit(SET_SIMforReseller, data.data);
  },
};

export const mutations = {
  SET_SIMForAdministrator(state, payload) {
    state.SIMForAdministrator = payload;
  },
  SET_SIMforSubscriber(state, payload) {
    state.SIMforSubscriber = payload;
  },
  SET_SIMforReseller(state, payload) {
    state.SIMforReseller = payload;
  },
};

const getters = {
  getSIMForAdministrator: function (state) {
    return `${state.SIMForAdministrator}`;
  },
  getSIMforSubscriber: function (state) {
    return `${state.SIMforSubscriber}`;
  },
  getSIMforReseller: function (state) {
    return `${state.SIMforReseller}`;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
