import { createRouter, createWebHashHistory } from "vue-router";

import DashboardPage from "./components/pages/Dashboard/DashboardPage.vue";
import ProfilePage from "./components/pages/Profile/ProfilePage.vue";
import TestPage from "./components/pages/Landing/TestPage.vue";
import NewDashboardPage from "./components/pages/NewDashboard/NewDashboardPage.vue";
import NewSIMSPage from "./components/pages/NewDashboard/NewSIMSPage.vue";
import NewPlanPage from "./components/pages/NewDashboard/NewPlanPage.vue";
import NewFulfilmentPage from "./components/pages/NewDashboard/NewFulfilmentPage.vue";
import NewInvoicePage from "./components/pages/NewDashboard/NewInvoicePage.vue";
import NewPaymentPage from "./components/pages/NewDashboard/NewPaymentPage.vue";
import NewResellerPage from "./components/pages/NewDashboard/NewResellerPage.vue";
import NewSubscribersPage from "./components/pages/NewDashboard/NewSubscribersPage.vue";
import NewRevenueReportsPage from "./components/pages/NewDashboard/NewRevenueReportsPage.vue";
import LoginPage from "./components/sections/Login/LoginSection.vue";
import NewUsageReportsPage from "./components/pages/NewDashboard/NewUsageReportsPage.vue";
import NewCostReportsPage from "./components/pages/NewDashboard/NewCostReportsPage.vue";
import NewCreateSubscriberPage from "./components/pages/NewDashboard/NewCreateSubscriberPage.vue";
import NewUploadSIMSubscribersPage from "./components/pages/NewDashboard/NewUploadSIMSubscribersPage.vue";
import NewCreateResellerPage from "./components/pages/NewDashboard/NewCreateResellerPage.vue";
import NewCreateFulfilmentPage from "./components/pages/NewDashboard/NewCreateFulfilmentPage.vue";
import DashobardMyProfilePage from "./components/pages/Dashboard/DashobardMyProfilePage.vue";
import DashobardOrderStatusPage from "./components/pages/Dashboard/DashobardOrderStatusPage.vue";
import DashobardPaymentInvoicePage from "./components/pages/Dashboard/DashobardPaymentInvoicePage.vue";
import DashobardPaymentMethodPage from "./components/pages/Dashboard/DashobardPaymentMethodPage.vue";
import DashobardSIMPage from "./components/pages/Dashboard/DashobardSIMPage.vue";
import PaymentSuccessfulPage from "./components/pages/Landing/PaymentSuccessfulPage.vue";
import PaymentFailurePage from "./components/pages/Landing/PaymentFailurePage.vue";
import DashboardCallLogPage from "./components/pages/Dashboard/DashboardCallLogPage.vue";

import { detecTheme } from "./common/detect1Psimdomain";

const { theme1psim, themePeer } = detecTheme();

const routes = [
  {
    path: "/",
    component: LoginPage,
    meta: {
      hideNavigation: false,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/LoginPage",
    component: LoginPage,
    meta: {
      hideNavigation: false,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/DashboardPage",
    component: DashboardPage,
    meta: {
      hideNavigation: false,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/DashboardPage/:id",
    component: DashboardPage,
    meta: {
      hideNavigation: false,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/ProfilePage",
    component: ProfilePage,
    meta: {
      hideNavigation: true,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/NewDashboardPage",
    component: NewDashboardPage,
    meta: {
      hideNavigation: true,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/NewSIMSPage",
    component: NewSIMSPage,
    meta: {
      hideNavigation: true,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/NewSubscribersPage",
    component: NewSubscribersPage,
    meta: {
      hideNavigation: true,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/NewResellerPage",
    component: NewResellerPage,
    meta: {
      hideNavigation: true,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/NewPaymentPage",
    component: NewPaymentPage,
    meta: {
      hideNavigation: true,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/NewInvoicePage",
    component: NewInvoicePage,
    meta: {
      hideNavigation: true,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/NewFulfilmentPage",
    component: NewFulfilmentPage,
    meta: {
      hideNavigation: true,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/NewPlanPage",
    component: NewPlanPage,
    meta: {
      hideNavigation: true,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/NewRevenueReportsPage",
    component: NewRevenueReportsPage,
    meta: {
      hideNavigation: true,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/TestPage",
    component: TestPage,
    meta: {
      hideNavigation: true,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/NewUsageReportsPage",
    component: NewUsageReportsPage,
    meta: {
      hideNavigation: true,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/NewCreateSubscriberPage",
    component: NewCreateSubscriberPage,
    meta: {
      hideNavigation: true,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/NewCostReportsPage",
    component: NewCostReportsPage,
    meta: {
      hideNavigation: true,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/NewUploadSIMSubscribersPage",
    component: NewUploadSIMSubscribersPage,
    meta: {
      hideNavigation: true,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/NewCreateResellerPage",
    component: NewCreateResellerPage,
    meta: {
      hideNavigation: true,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/NewCreateFulfilmentPage",
    component: NewCreateFulfilmentPage,
    meta: {
      hideNavigation: true,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/DashobardMyProfilePage",
    component: DashobardMyProfilePage,
    meta: {
      hideNavigation: false,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/DashobardOrderStatusPage",
    component: DashobardOrderStatusPage,
    meta: {
      hideNavigation: false,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/DashobardPaymentInvoicePage",
    component: DashobardPaymentInvoicePage,
    meta: {
      hideNavigation: false,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/DashobardPaymentMethodPage",
    component: DashobardPaymentMethodPage,
    meta: {
      hideNavigation: false,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/DashobardSIMPage",
    component: DashobardSIMPage,
    meta: {
      hideNavigation: false,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/PaymentSuccessfulPage",
    component: PaymentSuccessfulPage,
    meta: {
      hideNavigation: false,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/PaymentFailurePage",
    component: PaymentFailurePage,
    meta: {
      hideNavigation: false,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
  {
    path: "/DashboardCallLog",
    component: DashboardCallLogPage,
    meta: {
      hideNavigation: false,
      theme1psim: theme1psim,
      themePeer: themePeer,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
