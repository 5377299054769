<template>
  <div class="overflow-auto">
    <div class="section-dashboard-tabs">
      <div
        :class="[isActiveIndex == 1 ? 'item item-actvie' : 'item']"
        @click="setActiveItemId(1)"
      >
        <img v-if="isActiveIndex == 1" :src="dashboardIcon1Green" alt="" />
        <img v-if="isActiveIndex != 1" :src="dashboardIcon1" alt="" />
        Dashboard
      </div>
      <div
        :class="[isActiveIndex == 2 ? 'item item-actvie' : 'item']"
        @click="setActiveItemId(2)"
      >
        <img v-if="isActiveIndex == 2" :src="dashboardIcon2Green" alt="" />
        <img v-if="isActiveIndex != 2" :src="dashboardIcon2" alt="" /> SIM
      </div>
      <div
        :class="[isActiveIndex == 3 ? 'item item-actvie' : 'item']"
        @click="setActiveItemId(3)"
      >
        <img v-if="isActiveIndex == 3" :src="dashboardIcon3Green" alt="" />
        <img v-if="isActiveIndex != 3" :src="dashboardIcon3" alt="" />
        Payment
      </div>
      <div
        :class="[isActiveIndex == 4 ? 'item item-actvie' : 'item']"
        @click="setActiveItemId(4)"
      >
        <img v-if="isActiveIndex == 4" :src="dashboardIcon4Green" alt="" />
        <img v-if="isActiveIndex != 4" :src="dashboardIcon4" alt="" />
        Invoice
      </div>
      <div
        :class="[isActiveIndex == 5 ? 'item item-actvie' : 'item']"
        @click="setActiveItemId(5)"
      >
        <img v-if="isActiveIndex == 5" :src="dashboardIcon5Green" alt="" />
        <img v-if="isActiveIndex != 5" :src="dashboardIcon5" alt="" /> My
        profile
      </div>
      <div
        :class="[isActiveIndex == 6 ? 'item item-actvie' : 'item']"
        @click="setActiveItemId(6)"
      >
        <img v-if="isActiveIndex == 6" :src="dashboardIcon6Green" alt="" />
        <img v-if="isActiveIndex != 6" :src="dashboardIcon6" alt="" /> Order
        status
      </div>
      <div
        :class="[isActiveIndex == 7 ? 'item item-actvie' : 'item']"
        @click="setActiveItemId(7)"
      >
        <img v-if="isActiveIndex == 7" :src="dashboardIcon7Green" alt="" />
        <img v-if="isActiveIndex != 7" :src="dashboardIcon7" alt="" /> Call log
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isActiveIndex"],
  components: {},
  data() {
    return {
      dashboardIcon1: require("../../assets/images/pages/Dashboard/DashboardPage/Dashboard-Icon-1.svg"),
      dashboardIcon2: require("../../assets/images/pages/Dashboard/DashboardPage/Dashboard-Icon-2.svg"),
      dashboardIcon3: require("../../assets/images/pages/Dashboard/DashboardPage/Dashboard-Icon-3.svg"),
      dashboardIcon4: require("../../assets/images/pages/Dashboard/DashboardPage/Dashboard-Icon-4.svg"),
      dashboardIcon5: require("../../assets/images/pages/Dashboard/DashboardPage/Dashboard-Icon-5.svg"),
      dashboardIcon6: require("../../assets/images/pages/Dashboard/DashboardPage/Dashboard-Icon-6.svg"),
      dashboardIcon7: require("../../assets/images/pages/Dashboard/DashboardPage/Dashboard-Icon-7.svg"),

      dashboardIcon1Green: require("../../assets/images/pages/Dashboard/DashboardPage/Dashboard-Icon-1-green.svg"),
      dashboardIcon2Green: require("../../assets/images/pages/Dashboard/DashboardPage/Dashboard-Icon-2-green.svg"),
      dashboardIcon3Green: require("../../assets/images/pages/Dashboard/DashboardPage/Dashboard-Icon-3-green.svg"),
      dashboardIcon4Green: require("../../assets/images/pages/Dashboard/DashboardPage/Dashboard-Icon-4-green.svg"),
      dashboardIcon5Green: require("../../assets/images/pages/Dashboard/DashboardPage/Dashboard-Icon-5-green.svg"),
      dashboardIcon6Green: require("../../assets/images/pages/Dashboard/DashboardPage/Dashboard-Icon-6-green.svg"),
      dashboardIcon7Green: require("../../assets/images/pages/Dashboard/DashboardPage/Dashboard-Icon-7-green.svg"),
    };
  },
  methods: {
    setActiveItemId(number) {
      if (number == 1) this.$router.push("/DashboardPage");
      if (number == 2) this.$router.push("/DashobardSIMPage");
      if (number == 3) this.$router.push("/DashobardPaymentMethodPage");
      if (number == 4) this.$router.push("/DashobardPaymentInvoicePage");
      if (number == 5) this.$router.push("/DashobardMyProfilePage");
      if (number == 6) this.$router.push("/DashobardOrderStatusPage");
      if (number == 7) this.$router.push("/DashboardCallLog");
      if (number == 8) this.$router.push("/");
    },
  },
};
</script>

<style>
@import "../../assets/styles/helpers/dashboardTab.css";
</style>
