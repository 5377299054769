<template>
  <section class="mt-24 ml-26">
    <div>
      <img :src="avatar" class="dashboard-avatar" />
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      avatar: require("../../../assets/images/pages/NewDashboardPage/avatar.svg"),
    };
  },
};
</script>

<style>
@import "../../../assets/styles/components/pages/NewDashboard/Avatar.css";
</style>
