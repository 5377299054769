<template>
  <section class="section section-profile">
    <div>
      <h3>Personal Info</h3>
      <div class="general-card">
        <h4 class="mb-24">General Info</h4>
        <div class="d-flex mb-16">
          <div class="flex-grow-1">
            <label>First Name <span class="label-required">*</span></label>
            <input placeholder="John" type="text" class="mr-16 w-95" />
          </div>
          <div class="flex-grow-1">
            <label>Last Name <span class="label-required">*</span></label>
            <input placeholder="Doe" type="text" class="w-95" />
          </div>
        </div>
        <div class="d-flex mb-16">
          <div class="flex-grow-1">
            <label>Date of birth</label>
            <input placeholder="Day" type="number" class="mr-16 w-95" />
          </div>
          <div class="flex-grow-1">
            <label>&nbsp;</label>
            <input placeholder="Month" type="number" class="mr-16 w-95" />
          </div>
          <div class="flex-grow-1">
            <label>&nbsp;</label>
            <input placeholder="Year" type="number" class="w-95" />
          </div>
        </div>
        <div class="d-flex mb-16">
          <div class="flex-grow-1">
            <label
              >Last 4 digits of Social Security Number
              <span class="label-required">*</span></label
            >
            <input placeholder="0000" type="text" class="mr-16 w-95" />
          </div>
          <div class="flex-grow-1">
            <label>Language <span class="label-required">*</span></label>
            <input placeholder="English" type="text" class="w-95" />
          </div>
        </div>
        <div>
          <p>
            Your Soical Security Number is requireed in order to verify your
            aligibility and to receive benefits to our Government Assistance
            Program.
          </p>
        </div>
        <div class="d-flex flex-row-reverse">
          <router-link
            to="/ProfilePage?profileStep=2"
            class="btn-round btn-green"
            >Continue</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>

<style>
@import "../../../assets/styles/components/sections/Profile/01_PersonalnfoSection.css";
</style>
