export const getSubscriberId = () => {
  return localStorage.getItem("SubscriberId");
};

export const saveSubscriberId = (id) => {
  localStorage.setItem("SubscriberId", id);
};

export const destroySubscriberId = () => {
  localStorage.removeItem("SubscriberId");
};

export default { getSubscriberId, saveSubscriberId, destroySubscriberId };
