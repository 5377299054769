import ApiService from "../api.service";

const SIMService = {
  /* Administrator */
  getSIMforAdministrator() {
    return ApiService.get(`${process.env.VUE_APP_API_LINK}/administrator/sim`);
  },
  getSIMforAdministratorByID(simId) {
    return ApiService.get(
      `${process.env.VUE_APP_API_LINK}/administrator/sim/${simId}`
    );
  },
  postSIMforAdministrator() {
    return ApiService.post(`${process.env.VUE_APP_API_LINK}/administrator/sim`);
  },
  /* Administrator */

  /* Subscriber */
  getSIMforSubscriber() {
    return ApiService.get(`${process.env.VUE_APP_API_LINK}/subscriber/sim`);
  },
  getSIMforSubscriberById(id) {
    return ApiService.get(
      `${process.env.VUE_APP_API_LINK}/subscriber/sim/${id}`
    );
  },
  getSubscriberSIMServiceInfo(simId) {
    return ApiService.get(
      `${process.env.VUE_APP_API_LINK}/subscriber/sim/${simId}/service_info`
    );
  },
  postRenewSIMforSubscriber(id) {
    return ApiService.post(
      `${process.env.VUE_APP_API_LINK}/subscriber/sim/${id}/renew`,
      {
        paymentGateway: "stripe",
      }
    );
  },
  postAutoRenewSIMforSubscriber(id) {
    return ApiService.post(
      `${process.env.VUE_APP_API_LINK}/subscriber/sim/${id}/auto_renewal`,
      {
        simId: id,
      }
    );
  },
  /* Subscriber */

  /* Reseller */
  getSIMforReseller() {
    return ApiService.get(
      "",
      `${process.env.VUE_APP_API_LINK}/reseller/subscriber`
    );
  },
  /* Reseller */
};

export default SIMService;
