<template>
  <section class="section section--bottom--space">
    <div>
      <div class="general-card">
        <div class="table-responsive">
          <table class="table table-sim">
            <thead>
              <tr>
                <td scope="col" class="fw-500">ID</td>
                <td scope="col" class="fw-500">Date</td>
                <td scope="col" class="fw-500">Amount</td>
                <td scope="col" class="fw-500"></td>
              </tr>
            </thead>
            <tbody>
              <template
                :key="index"
                v-for="(data, index) in invoicesSubscriber"
              >
                <tr>
                  <td>{{ data.id }}</td>
                  <td>{{ this.cleanDate(data.startUsagePeriod) }}</td>
                  <td>{{ data.amount }}</td>
                  <td class="float-right">
                    <button
                      @click="
                        () =>
                          payInvoice(
                            data.id,
                            data.sim.simSubscription.planSubscription.plan.id
                          )
                      "
                      class="btn-small-green"
                    >
                      Pay invoice
                    </button>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import InvoiceService from "../../../common/services/invoiceService";

export default {
  components: {},
  data() {
    return {
      invoicesSubscriber: {},
    };
  },
  mounted() {
    this.fetchInvoices();
  },
  methods: {
    cleanDate(date) {
      if (date === null || date === undefined) return;
      return date.slice(0, -5).replace("T", " ");
    },
    async payInvoice(id, planId) {
      const data = await InvoiceService.postInvoiceSubscriberPay(id, planId);
      console.log(data.data.url);
      if (data.data.url) window.location.href = data.data.url;
    },
    async fetchInvoices() {
      await this.$store.dispatch("addInvoiceSubscriber");
      this.invoicesSubscriber =
        this.$store.state.DashobardSectionInvoiceStore.invoicesSubscriber;
    },
  },
};
</script>
