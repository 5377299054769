<template>
  <div
    class="body"
    :class="[$route.meta.theme1psim === true ? 'theme1psim' : 'themePeer']"
  >
    <Header1Sim v-if="!$route.meta.hideNavigation && $route.meta.theme1psim" />
    <Header v-if="!$route.meta.hideNavigation && $route.meta.themePeer" />

    <router-view />

    <Footer v-if="!$route.meta.hideNavigation && $route.meta.themePeer" />
    <Footer1Sim v-if="!$route.meta.hideNavigation && $route.meta.theme1psim" />
  </div>
</template>

<script>
import Header from "./components/layouts/Header.vue";
import Footer from "./components/layouts/Footer.vue";

import Header1Sim from "./assets/theme/1pSim/layouts/Header.vue";
import Footer1Sim from "./assets/theme/1pSim/layouts/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    Header1Sim,
    Footer1Sim,
  },
};

require("./assets/theme/1pSim/button.css");
require("./assets/theme/1pSim/fonts.css");
require("./assets/theme/1pSim/varaibles.css");
require("./assets/theme/1pSim/theme.css");
require("./assets/theme/1pSim/footer.css");
require("./assets/theme/1pSim/header.css");

require("./assets/styles/varaibles.css");
</script>

<style>
@import "./assets/styles/libraries/bootstrap.min.css";
@import "./assets/styles/libraries/flicking.css";
@import "./assets/styles/libraries/flicking-inline.css";
@import "./assets/styles/libraries/animate.min.css";

@import "./assets/styles/libraries/normalize.css";
@import "./assets/styles/fonts.css";

@import "./assets/styles/icons.css";
@import "./assets/styles/inputs.css";
@import "./assets/styles/spaces.css";
@import "./assets/styles/colors.css";
@import "./assets/styles/title.css";
@import "./assets/styles/buttons.css";
@import "./assets/styles/general.css";
</style>
