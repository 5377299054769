import InvoiceService from "../../common/services/invoiceService";

import { SET_Invoices } from "../actions.type";

export const state = {
  invoicesSubscriber: {},
};

export const actions = {
  async addInvoiceSubscriber(context) {
    const data = await InvoiceService.getInvoiceSubscriber();
    return context.commit(SET_Invoices, data.data);
  },
};

export const mutations = {
  SET_Invoices(state, payload) {
    state.invoicesSubscriber = payload;
  },
};

const getters = {
  getInvoices: function (state) {
    return `${state.invoicesSubscriber}`;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
