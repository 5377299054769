<template>
  <div class="header-1pSim">
    <div class="row">
      <div class="col-md-3 col-sm-6 col-xs-6 col-6">
        <router-link to="/">
          <img :src="iconLogo" alt="" class="iconLogo-1pSim" />
        </router-link>
      </div>
      <div class="col-md-8 d-none d-md-block">
        <ul class="header-menu-1pSim">
          <li>
            <a
              href="#"
              class="header-menu-link-1pSim header-menu-link-dropdown-1pSim"
            >
              Plans
            </a>
          </li>
          <li>
            <a href="#" class="header-menu-link-1pSim"> Active </a>
          </li>
          <li>
            <a href="#" class="header-menu-link-1pSim"> Intenational rate </a>
          </li>
          <li>
            <a href="#" class="header-menu-link-1pSim"> Shipping fee </a>
          </li>
          <li>
            <a href="#" class="header-menu-link-1pSim"> About </a>
          </li>
          <li>
            <a href="#" class="header-menu-link-1pSim"> Support </a>
          </li>
        </ul>
      </div>
      <div class="col-md-1 col-sm-6 col-xs-6 col-6">
        <img class="iconShop-1pSim" :src="iconShop" alt="" />
        <!-- <img class="iconShop-1pSim" :src="notEmptyIconShop" alt="" /> -->
        <NavBar1pSIM />
      </div>
    </div>
  </div>
</template>

<script>
import NavBar1pSIM from "../components/NavBar1pSIM.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header1Sim",
  components: {
    NavBar1pSIM,
  },
  data() {
    return {
      iconLogo: require("../images/logo.svg"),
      // iconShop: require("../images/cart.svg"),
      notEmptyIconShop: require("../images/notEmptyCard.svg"),
    };
  },
};
</script>

<style></style>
