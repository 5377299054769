<template>
  <section class="new-dashboard-bg">
    <div class="row pr-32">
      <div class="col-md-3">
        <NewDashboardNavbar />
      </div>
      <div class="col-md-9">
        <div class="d-flex justify-content-between">
          <Search />
          <div class="d-flex">
            <Notification />
            <Avatar />
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <div>
            <DashboardTitlte title="Reseller" />
          </div>
          <div>
            <div class="d-flex mt-53">
              <div class="btn-black btn-black--ghost mr-16">Cancel</div>
              <div class="btn-black">Submit</div>
            </div>
          </div>
        </div>

        <div
          class="dashboard-content dashboard-content-subscriber mb-16 backdown-24"
        >
          <h4 class="mb-24">Create Reseller</h4>
          <div class="mb-16">
            <input
              v-model="text"
              class="w-100"
              type="text"
              placeholder="Alias"
            />
          </div>
          <div class="mb-16">
            <input
              v-model="email"
              class="w-100"
              type="email"
              placeholder="Email"
            />
          </div>
          <div class="mb-16">
            <input
              v-model="Password"
              class="w-100"
              type="Password"
              placeholder="Password"
            />
          </div>
          <div class="mb-16">
            <input
              v-model="Password"
              class="w-100"
              type="Password"
              placeholder="Confirm Password"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Search from "../../sections/NewDashboard/Search.vue";
import NewDashboardNavbar from "../../layouts/NewDashboardNavbar.vue";
import DashboardTitlte from "../../helpers/DashboardTitle.vue";
import Notification from "../../sections/NewDashboard/Notification.vue";
import Avatar from "../../sections/NewDashboard/Avatar.vue";

export default {
  components: {
    NewDashboardNavbar,
    DashboardTitlte,
    Notification,
    Search,
    Avatar,
  },
  data() {
    return {};
  },
};
</script>

<style>
@import "../../../assets/styles/components/pages/NewDashboard/NewDashboard.css";
</style>
