import ApiService from "../api.service";

export const InvoiceService = {
  getInvoiceAdministrator() {
    return ApiService.get(
      `${process.env.VUE_APP_API_LINK}/administrator/invoice`
    );
  },
  getInvoiceAdministratorByID(id) {
    return ApiService.get(
      `${process.env.VUE_APP_API_LINK}/administrator/invoice/${id}`
    );
  },
  getInvoiceReseller() {
    return ApiService.get(`${process.env.VUE_APP_API_LINK}/reseller/invoice`);
  },
  getInvoiceReselleryByID(id) {
    return ApiService.get(
      `${process.env.VUE_APP_API_LINK}/reseller/invoice/${id}`
    );
  },
  getInvoiceSubscriber() {
    return ApiService.get(`${process.env.VUE_APP_API_LINK}/subscriber/invoice`);
  },
  getInvoiceSubscriberByID(id) {
    return ApiService.get(
      `${process.env.VUE_APP_API_LINK}/subscriber/invoice/${id}`
    );
  },
  postInvoiceSubscriberPay(id, planId) {
    return ApiService.post(
      `${process.env.VUE_APP_API_LINK}/subscriber/invoice/${id}/pay`,
      {
        paymentGateway: "stripe",
        planId,
      }
    );
  },
};

export default InvoiceService;
