<template>
  <section class="new-dashboard-bg">
    <div class="row pr-32">
      <div class="col-md-3">
        <NewDashboardNavbar />
      </div>
      <div class="col-md-9">
        <div class="d-flex justify-content-between">
          <Search />
          <div class="d-flex">
            <Notification />
            <Avatar />
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <div>
            <DashboardTitlte title="Subscribers" />
          </div>
          <div>
            <div class="d-flex mt-53">
              <div class="btn-black btn-black--ghost mr-16">Cancel</div>
              <div class="btn-black">Submit</div>
            </div>
          </div>
        </div>

        <div
          class="dashboard-content dashboard-content-subscriber mb-16 backdown-24"
        >
          <h4 class="mb-24">Upload SIM</h4>

          <nav class="d-flex mb-16 text-center" @click="myFunction">
            <div class="flex-item active">
              <img class="mr-4" :src="uploadIcon" alt="" /> Upload
            </div>
            <div class="flex-item">
              <img class="mr-4" :src="copyPasteIcon" alt="" />Copy & Paste
            </div>
          </nav>

          <form class="mb-30" @submit.prevent="generateTokenSubmit">
            <div class="form-group">
              <div>
                <div v-if="!file">
                  <div
                    :class="['dropZone', dragging ? 'dropZone-over' : '']"
                    @dragenter="dragging = true"
                    @dragleave="dragging = false"
                  >
                    <div class="dropZone-info" @drag="onChange">
                      <span class="fa fa-cloud-upload dropZone-title"></span>
                      <div>
                        <img class="mb-12" :src="dragAndDropIcon" />
                      </div>
                      <span class="dropZone-title"
                        >Drag & drop or
                        <span class="main-green-color">Browse</span> to
                        upload</span
                      >
                      <div class="dropZone-upload-limit-info"></div>
                    </div>
                    <form
                      id="uploadForm"
                      enctype="multipart/form-data"
                      v-on:change="onChange"
                    >
                      <input type="file" />
                    </form>
                  </div>
                </div>
                <div v-else class="dropZone-uploaded">
                  <div class="dropZone-uploaded-info">
                    <span class="dropZone-title">{{ file.name }}</span>
                    <button
                      type="button"
                      class="btn btn-danger removeFile"
                      @click="removeFile"
                    >
                      Remove File
                    </button>
                  </div>
                </div>

                <div class="uploadedFile-info"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Search from "../../sections/NewDashboard/Search.vue";
import NewDashboardNavbar from "../../layouts/NewDashboardNavbar.vue";
import DashboardTitlte from "../../helpers/DashboardTitle.vue";
import Notification from "../../sections/NewDashboard/Notification.vue";
import Avatar from "../../sections/NewDashboard/Avatar.vue";

export default {
  components: {
    Search,
    NewDashboardNavbar,
    DashboardTitlte,
    Notification,
    Avatar,
  },
  data() {
    return {
      file: "",
      dragging: false,

      copyPasteIcon: require("../../../assets/images/pages/NewDashboardPage/copyPasteIcon.svg"),
      uploadIcon: require("../../../assets/images/pages/NewDashboardPage/uploadIcon.svg"),
      dragAndDropIcon: require("../../../assets/images/pages/NewDashboardPage/dragAndDropIcon.svg"),
    };
  },
};
</script>

<style>
@import "../../../assets/styles/components/pages/NewDashboard/NewDashboard.css";
@import "../../../assets/styles/helpers/dropZone.css";

@import "../../../assets/styles/helpers/dropdown.css";
</style>
