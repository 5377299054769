/* SIM */
export const SET_SIMForAdministrator = "SET_SIMForAdministrator";
export const SET_SIMforSubscriber = "SET_SIMforSubscriber";
export const SET_SIMforReseller = "SET_SIMforReseller";
/* SIM */

/* PROFILE */
export const SET_Profile = "SET_Profile";
/* PROFILE */

/* INVOICE */
export const SET_Invoices = "SET_Invoices";
/* INVOICE */

/* ORDER STATUS */
export const SET_Orders = "SET_Orders";
/* ORDER STATUS */

/* DASHBOARD TAB */
export const SET_DashboardTab = "SET_DashboardTab";
/* DASHBOARD TAB */

export const SET_USERRoleAdministrator = "SET_USERRoleAdministrator";
export const SET_USERRoleReseller = "SET_USERRoleReseller";
export const SET_USERRoleSubscriber = "SET_USERRoleSubscriber";

export const SET_CallLogs = "SET_CallLogs";

export const SET_AddOn = "SET_AddOn";
