import { SET_DashboardTab } from "../actions.type";

export const state = {
  dashboardTab: 1,
};

export const actions = {
  addDashboardTab(context, payload) {
    return context.commit(SET_DashboardTab, payload);
  },
};

export const mutations = {
  SET_DashboardTab(state, payload) {
    state.dashboardTab = payload;
  },
};

const getters = {
  getDashboardTab: function (state) {
    return `${state.dashboardTab}`;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
