<template>
  <section class="new-dashboard-bg">
    <div class="row pr-32">
      <div class="col-md-3">
        <NewDashboardNavbar />
      </div>
      <div class="col-md-9">
        <div class="d-flex justify-content-between">
          <Search />
          <div class="d-flex">
            <Notification />
            <Avatar />
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <div>
            <DashboardTitlte title="Subscribers" />
          </div>
          <div>
            <div class="d-flex mt-53">
              <router-link
                to="/NewCreateSubscriberPage"
                class="btn-black btn-black--plus"
                >Create Subscriber</router-link
              >
            </div>
          </div>
        </div>
        <div class="dashboard-content mb-16 backdown-24">
          <table class="table table-bordered dashboard-content-table">
            <thead>
              <tr>
                <th scope="col">Email</th>
                <th scope="col">Created on</th>
                <th scope="col">Reseller</th>
                <th scope="col">status</th>
                <th scope="col">sim counts</th>
                <th scope="col">created by</th>
              </tr>
            </thead>
            <tbody>
              <template
                :key="index"
                v-for="(simSubscription, index) in SIMForAdministrator"
              >
                <tr>
                  <th scope="row">Dec 18, 2022</th>
                  <td>877</td>
                  <td>9374</td>
                  <td>8013</td>
                  <td>5948</td>
                  <td>
                    <div
                      v-if="
                        simSubscription.simSubscription.planSubscription.plan
                          .isActive == true
                      "
                      class="table-sim-active"
                    >
                      Active
                    </div>
                    <div
                      v-if="
                        simSubscription.simSubscription.planSubscription.plan
                          .isActive == false
                      "
                      class="table-sim-not-active"
                    >
                      Not Active
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Search from "../../sections/NewDashboard/Search.vue";
import NewDashboardNavbar from "../../layouts/NewDashboardNavbar.vue";
import DashboardTitlte from "../../helpers/DashboardTitle.vue";
import Notification from "../../sections/NewDashboard/Notification.vue";
import Avatar from "../../sections/NewDashboard/Avatar.vue";

export default {
  components: {
    Search,
    NewDashboardNavbar,
    DashboardTitlte,
    Notification,
    Avatar,
  },
  data() {
    return {};
  },
  mounted() {
    this.setDashboardTab();
  },
  methods: {
    setDashboardTab() {
      this.$store.dispatch("addDashboardTab", 3);
    },
  },
};
</script>

<style>
@import "../../../assets/styles/components/pages/NewDashboard/NewDashboard.css";
</style>
