<template>
  <section class="section section--bottom--space">
    <div>
      <div class="general-card">
        <div class="row pb-24 borderBottom">
          <div class="col-md-4 fw-500">General Info</div>
          <div class="col-md-8">
            <div class="d-flex">
              <div class="mb-16 mr-16 flex-grow-1">
                <label>
                  First Name <span class="label-required">*</span>
                </label>
                <input
                  required
                  class="w-100"
                  type="text"
                  placeholder="John"
                  v-model="firstName"
                />
              </div>
              <div class="flex-grow-1">
                <label> Last Name <span class="label-required">*</span> </label>
                <input
                  required
                  class="w-100"
                  type="text"
                  placeholder="Doe"
                  v-model="lastName"
                />
              </div>
            </div>
            <!-- <div class="d-flex">
              <div class="mr-16 flex-grow-1">
                <label> Date of birth </label>
                <input
                  v-model="dayBirthDate"
                  class="w-100"
                  type="text"
                  placeholder="Day"
                />
              </div>
              <div class="mr-16 flex-grow-1">
                <label> &nbsp; </label>
                <input
                  v-model="monthBirthDate"
                  class="w-100"
                  type="text"
                  placeholder="Month"
                />
              </div>
              <div class="flex-grow-1">
                <label> &nbsp; </label>
                <input
                  v-model="yearBirthDate"
                  class="w-100"
                  type="text"
                  placeholder="Year"
                />
              </div>
            </div> -->
          </div>
        </div>
        <div class="row pb-24 pt-24 borderBottom">
          <div class="col-md-4 fw-500">Contact Info</div>
          <div class="col-md-8">
            <div class="d-flex">
              <div class="mr-16 flex-grow-1">
                <label>
                  Phone Number <span class="label-required">*</span>
                </label>
                <input
                  required
                  class="w-100"
                  type="text"
                  placeholder="718-733-3333"
                  v-model="phone"
                />
              </div>
              <div class="flex-grow-1">
                <label> Email <span class="label-required">*</span> </label>
                <input
                  required
                  class="w-100"
                  type="text"
                  placeholder="johndoe@example.com"
                  v-model="email"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row pb-24 pt-24">
          <div class="col-md-4 fw-500">Address</div>
          <div class="col-md-8">
            <div class="d-flex">
              <div class="mb-16 mr-16 flex-grow-1">
                <label> Country <span class="label-required">*</span> </label>
                <input
                  required
                  class="w-100"
                  type="text"
                  placeholder="US"
                  v-model="country"
                />
              </div>
              <div class="flex-grow-1">
                <label> City <span class="label-required">*</span> </label>
                <input
                  required
                  class="w-100"
                  type="text"
                  placeholder="Houston"
                  v-model="city"
                />
              </div>
            </div>
            <div class="d-flex">
              <div class="mb-16 mr-16 flex-grow-1">
                <label> Address <span class="label-required">*</span> </label>
                <input
                  required
                  class="w-100"
                  type="text"
                  placeholder="6370 Long Dr, 77087"
                  v-model="shippingAddress1"
                />
              </div>
              <div class="flex-grow-1">
                <label> State <span class="label-required">*</span> </label>
                <input
                  required
                  class="w-100"
                  type="text"
                  placeholder="Texas"
                  v-model="shippingAddress2"
                />
              </div>
            </div>
          </div>
        </div>
        <input
          type="button"
          v-on:click="() => updateProfile()"
          class="btn-round btn-green mb-0"
          value="Save Changes"
        />
      </div>
    </div>
  </section>
</template>

<script>
import ProfileService from "../../../common/services/profileService";

export default {
  data() {
    return {};
  },
  mounted() {
    this.fetchProfile();
  },
  computed: {
    dayBirthDate: {
      get() {
        let dayBirthDate =
          this.$store.state.DashobardSectionMyProfileStore.profile.birthDate;

        return dayBirthDate ? dayBirthDate.split("/")[0] : null;
      },
    },
    monthBirthDate: {
      get() {
        let dayBirthDate =
          this.$store.state.DashobardSectionMyProfileStore.profile.birthDate;

        return dayBirthDate ? dayBirthDate.split("/")[1] : null;
      },
    },
    yearBirthDate: {
      get() {
        let dayBirthDate =
          this.$store.state.DashobardSectionMyProfileStore.profile.birthDate;

        return dayBirthDate ? dayBirthDate.split("/")[2] : null;
      },
    },
    firstName: {
      get() {
        return this.$store.state.DashobardSectionMyProfileStore.profile
          .firstName;
      },
    },
    lastName: {
      get() {
        return this.$store.state.DashobardSectionMyProfileStore.profile
          .lastName;
      },
    },
    email: {
      get() {
        return this.$store.state.DashobardSectionMyProfileStore.profile.email;
      },
    },
    phone: {
      get() {
        return this.$store.state.DashobardSectionMyProfileStore.profile.phone;
      },
    },
    shippingAddress1: {
      get() {
        return this.$store.state.DashobardSectionMyProfileStore.profile
          .shippingAddress1;
      },
    },
    shippingAddress2: {
      get() {
        return this.$store.state.DashobardSectionMyProfileStore.profile
          .shippingAddress2;
      },
    },
    city: {
      get() {
        return this.$store.state.DashobardSectionMyProfileStore.profile.city;
      },
    },
    country: {
      get() {
        return this.$store.state.DashobardSectionMyProfileStore.profile.country;
      },
    },
    zipcode: {
      get() {
        return this.$store.state.DashobardSectionMyProfileStore.profile.zipcode;
      },
    },
  },
  methods: {
    separateBirthdaydate(data) {
      return data.split("/");
    },
    fetchProfile() {
      this.$store.dispatch("addProfile");
    },
    async updateProfile() {
      await ProfileService.patchSubscriberProfile({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phone: this.phone,
        shippingAddress1: this.shippingAddress1,
        shippingAddress2: this.shippingAddress2,
        city: this.city,
        country: this.country,
        zipcode: this.zipcode,
        birthDate: `${this.monthBirthDate}/${this.dayBirthDate}/${this.yearBirthDate}`,
      });
    },
  },
};
</script>
