<template>
  <section class="section section-profile">
    <div>
      <h3>Personal Info</h3>
      <div class="general-card">
        <div class="mb-24">
          <h4>Contact Info</h4>
        </div>
        <div class="d-flex mb-32">
          <div class="flex-grow-1">
            <label>Phone Number <span class="label-required">*</span></label>
            <input
              placeholder="Enter Phone Number"
              type="text"
              class="w-95 mr-16"
            />
          </div>
          <div class="flex-grow-1">
            <label>Email <span class="label-required">*</span></label>
            <input
              placeholder="Enter Email Address"
              type="email"
              class="w-95"
            />
          </div>
        </div>
        <div>
          <p>
            To receive important notifications regarding your appilcation, we
            encourange you to use a phone number taht can receive text messages.
            This should not be a landline number.
          </p>
        </div>
        <div class="d-flex mb-32">
          <div>
            <input class="mr-8" type="checkbox" name="scales" />
          </div>
          <div>
            <label
              >I consern to be contacted via email, phone, text message, and by
              mail.</label
            >
          </div>
        </div>
        <div class="mb-32">
          <label>Adress <span class="label-required">*</span></label>
          <input placeholder="Address" type="text" class="w-95" />
        </div>
        <div class="mb-16">
          <p>
            To verify application, your provided adress must match the adress
            listed on your ID. If your current adress does not much the adress
            in your ID, additional adress proot documents are required.
          </p>
        </div>
        <div class="d-flex">
          <div>
            <input class="mr-8" type="checkbox" name="scales" />
          </div>
          <div>
            <label>Mailing adress same with above adress</label>
          </div>
        </div>
        <div class="d-flex">
          <div>
            <input class="mr-8" type="checkbox" name="scales" />
          </div>
          <div>
            <label>Check the box if this a temporary adress</label>
          </div>
        </div>
        <div class="d-flex justify-content-between mt-32">
          <router-link
            to="/ProfilePage?profileStep=1"
            class="btn-round btn-black-ghost"
            >Back</router-link
          >
          <router-link
            to="/ProfilePage?profileStep=3"
            class="btn-round btn-green"
            >Continue</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>
