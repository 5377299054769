<template>
  <section class="section section-payment-dashboard section--bottom--space">
    <div>
      <div class="row">
        <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12">
          <div class="general-card">
            <div class="section-payment-dashboard--payment">
              <div class="d-flex mb-16">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input custom-control-input mr-10"
                    v-on:click="clickAutoTopUp()"
                    type="checkbox"
                    role="switch"
                  />
                  Auto Top Up
                </div>
                <!-- TO DO: GET API DATA -->
                <!-- <div class="ml-26">XXXX 1234</div> -->
                <div class="ml-26 fs-11 mt-8">
                  <router-link to="/DashobardMyProfilePage?tab=finance">
                    (define new credit card)
                  </router-link>
                </div>
              </div>
              <div class="mb-32" v-if="clickAutoTopUpData === true">
                <div class="d-flex">
                  <div class="mr-16">
                    <label>Top Up</label>
                    <input
                      placeholder="$25"
                      class="w-100"
                      type="text"
                      v-model="topUp"
                    />
                  </div>
                  <div>
                    <label> When balance is below </label>
                    <input
                      placeholder="$100"
                      class="w-100"
                      type="text"
                      v-model="balanceBelow"
                    />
                  </div>
                </div>
                <div class="d-flex mt-16 mb-32">
                  <div>
                    <input class="mr-8" type="checkbox" name="scales" />
                  </div>
                  <div>
                    <label>Reuse credit card detail from auto renew.</label>
                  </div>
                </div>
              </div>
              <div class="d-flex mb-16">
                <div class="form-check form-switch">
                  <input
                    v-on:click="clickAutoRenePlan()"
                    class="form-check-input custom-control-input mr-10"
                    type="checkbox"
                    role="switch"
                  />
                  Auto Renew Plan
                </div>
              </div>
              <div class="d-flex mb-32">
                <div>
                  <input class="mr-8" type="checkbox" name="scales" />
                </div>
                <div>
                  <label>Reuse credit card detail from auto renew.</label>
                </div>
              </div>

              <div class="d-flex">
                <div class="flex-item mb-16 mr-16">
                  <label>
                    Name on Card <span class="label-required">*</span></label
                  >
                  <input
                    placeholder="John Doe"
                    class="w-100"
                    type="text"
                    v-model="nameCard"
                  />
                </div>
                <div class="flex-item mb-16">
                  <label>
                    Card Number <span class="label-required">*</span></label
                  >
                  <input
                    placeholder="Enter card number"
                    class="w-100"
                    type="text"
                    v-model="cardNubmer"
                  />
                </div>
              </div>

              <div class="d-flex">
                <div class="flex-item mb-16 mr-16">
                  <label
                    >Expiry Date <span class="label-required">*</span></label
                  >
                  <input
                    placeholder="MM / YY"
                    class="w-100"
                    type="text"
                    v-model="expiryDate"
                  />
                </div>
                <div class="flex-item mb-16">
                  <label
                    >Security Code <span class="label-required">*</span></label
                  >
                  <div class="input-helper">
                    <input
                      placeholder="***"
                      class="w-100"
                      type="password"
                      v-model="securityCode"
                    />
                  </div>
                </div>
              </div>

              <div class="d-flex">
                <div class="flex-item mb-16 mr-16">
                  <label>
                    Address 1 <span class="label-required">*</span></label
                  >
                  <input
                    placeholder="Enter Address 1"
                    class="w-100"
                    type="text"
                    v-model="adress1"
                  />
                </div>
                <div class="flex-item mb-16">
                  <label>
                    Address 2 <span class="label-required">*</span></label
                  >
                  <input
                    placeholder="Enter Address 2"
                    class="w-100"
                    type="text"
                    v-model="adress2"
                  />
                </div>
              </div>

              <div class="d-flex">
                <div class="flex-item mb-16 mr-16">
                  <label> City <span class="label-required">*</span></label>
                  <input
                    placeholder="Enter City"
                    class="w-100"
                    type="text"
                    v-model="city"
                  />
                </div>
                <div class="flex-item mb-16">
                  <label
                    >Zip/Postal Code
                    <span class="label-required">*</span></label
                  >
                  <div class="input-helper">
                    <input
                      placeholder="00-000"
                      class="w-100"
                      type="text"
                      v-model="zipCode"
                    />
                  </div>
                </div>
              </div>

              <div class="d-flex">
                <div class="flex-item mb-16 mr-16">
                  <label> Phone number </label>
                  <input
                    placeholder="Enter Phone number"
                    class="w-100"
                    type="text"
                    v-model="phoneNumber"
                  />
                </div>
                <div class="flex-item mb-16">
                  <label> State <span class="label-required">*</span></label>
                  <input
                    placeholder="Enter State"
                    class="w-100"
                    type="text"
                    v-model="state"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  methods: {
    clickAutoTopUp() {
      this.clickAutoTopUpData = !this.clickAutoTopUpData;
    },
    clickAutoRenePlan() {
      this.clickAutoRenePlanData = !this.clickAutoRenePlanData;
    },
  },
  data() {
    return {
      clickAutoTopUpData: false,
      clickAutoRenePlanData: false,

      topUp: "",
      balanceBelow: "",
      nameCard: "",
      cardNubmer: "",
      adress1: "",
      adress2: "",
      city: "",
      state: "",
      phoneNumber: "",
      expiryDate: "",
      securityCode: "",
      zipCode: "",
    };
  },
};
</script>

<style>
@import "../../../assets/styles/components/sections/Dashboard/03_DashobardSectionPaymentMethod.css";
</style>
