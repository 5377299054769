<template>
  <section class="new-dashboard-bg">
    <div class="row pr-32">
      <div class="col-md-3">
        <NewDashboardNavbar />
      </div>
      <div class="col-md-9">
        <div class="d-flex justify-content-between">
          <Search />
          <div class="d-flex">
            <Notification />
            <Avatar />
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <div>
            <DashboardTitlte title="SIM Management" />
          </div>
          <div>
            <div class="d-flex mt-53">
              <router-link
                to="/NewUploadSIMSubscribersPage"
                class="btn-black btn-black--upload mr-16"
                >Upload SIM</router-link
              >
              <div class="btn-black btn-black--plus">Create SIM</div>
            </div>
          </div>
        </div>
        <div class="dashboard-content backdown-16-24">
          <div class="d-flex justify-content-between">
            <div>
              <div class="dropdown">
                <button
                  class="btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  10
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <a class="dropdown-item" href="#">Something else here</a>
                </div>
              </div>
            </div>
            <div class="d-flex mb-16">
              <div class="position-relative">
                <input
                  type="text"
                  placehoder="Search"
                  class="btn-search mr-16"
                />
              </div>
              <div class="btn-filter">Filter</div>
            </div>
          </div>
          <table class="table table-bordered dashboard-content-table">
            <thead>
              <tr>
                <th scope="col">ESN Number</th>
                <th scope="col">Created on</th>
                <th scope="col">activated on</th>
                <th scope="col">Destroyed On</th>
                <th scope="col">Subscriber</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              <template
                :key="index"
                v-for="(simSubscription, index) in SIMForAdministrator"
              >
                <tr>
                  <th scope="row">Dec 18, 2022</th>
                  <td>877</td>
                  <td>9374</td>
                  <td>8013</td>
                  <td>5948</td>
                  <td>
                    <div
                      v-if="
                        simSubscription.simSubscription.planSubscription.plan
                          .isActive == true
                      "
                      class="table-sim-active"
                    >
                      Active
                    </div>
                    <div
                      v-if="
                        simSubscription.simSubscription.planSubscription.plan
                          .isActive == false
                      "
                      class="table-sim-not-active"
                    >
                      Not Active
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Search from "../../sections/NewDashboard/Search.vue";
import NewDashboardNavbar from "../../../components/layouts/NewDashboardNavbar.vue";
import DashboardTitlte from "../../../components/helpers/DashboardTitle.vue";
import Notification from "../../../components/sections/NewDashboard/Notification.vue";
import Avatar from "../../../components/sections/NewDashboard/Avatar.vue";

export default {
  components: {
    Search,
    NewDashboardNavbar,
    DashboardTitlte,
    Notification,
    Avatar,
  },
  data() {
    return {};
  },
  mounted() {
    this.fetchSIM();
    this.setDashboardTab();
  },
  methods: {
    fetchSIM() {
      this.$store.dispatch("addSIMForAdministrator");
    },
    setDashboardTab() {
      this.$store.dispatch("addDashboardTab", 2);
    },
  },
  computed: {
    getSIMforAdministrator: {
      get() {
        return this.$store.state.DashobardSectionSIMStore
          .getSIMforAdministrator;
      },
    },
  },
};
</script>

<style>
@import "../../../assets/styles/components/pages/NewDashboard/NewDashboard.css";
</style>
