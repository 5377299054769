<template>
  <div class="cardComponent">
    <div class="mb-20">
      <div class="d-flex">
        <img v-if="title == 'Total SIM'" :src="iconTotalSim" alt="" />
        <img v-if="title == 'Assigned SIM'" :src="iconDestoryedSim" alt="" />
        <img v-if="title == 'Destroyed SIM'" :src="iconDestoryedSim" alt="" />
        <img
          v-if="title == 'Current Week Sales'"
          :src="idconCurrentWeakSales"
          alt=""
        />
        <img
          v-if="title == 'Current Month Sales'"
          :src="iconCurrentMonthSales"
          alt=""
        />
        <img
          v-if="title == 'Yeast to Date Sales'"
          :src="iconYeastToDateSales"
          alt=""
        />

        <div class="cardtitle">
          {{ title }}
        </div>
      </div>
    </div>
    <div class="cardNumber">
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "value"],
  components: {},
  data() {
    return {
      iconTotalSim: require("../../assets/images/components/cardComponent/totalSim.svg"),
      iconAssignedSim: require("../../assets/images/components/cardComponent/assignedSim.svg"),
      iconDestoryedSim: require("../../assets/images/components/cardComponent/destoryedSim.svg"),
      idconCurrentWeakSales: require("../../assets/images/components/cardComponent/CurrentWeakSales.svg"),
      iconCurrentMonthSales: require("../../assets/images/components/cardComponent/CurrentMonthSales.svg"),
      iconYeastToDateSales: require("../../assets/images/components/cardComponent/YeastToDateSales.svg"),
    };
  },
};
</script>

<style>
@import "./../../assets/styles/helpers/cardComponent.css";
</style>
