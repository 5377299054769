import ApiService from "../api.service";
// import { getSubscriberId } from "../subscriberId.service";

const TopUpService = {
  postTopUpService(amount) {
    return ApiService.post(
      `${process.env.VUE_APP_API_LINK}/subscriber/top_up`,
      {
        paymentGateway: "stripe",
        amount: +amount,
      }
    );
  },
};

export default TopUpService;
