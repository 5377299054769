<template>
  <section class="section section-profileTitle">
    <div class="container container-1170">
      <h2 v-if="profileStep < 5">
        Profile <span class="main-green-color">Info</span>
      </h2>
      <h2 v-if="profileStep == 5">
        Profile <span class="main-green-color">completed successfully</span>
      </h2>
    </div>
  </section>

  <section class="section pb-80">
    <div class="container container-1170">
      <div class="row">
        <div class="col-md-3">
          <ProflieBreadcrumbs :stepNum="profileStep" />
        </div>
        <div class="col-md-8 col">
          <div v-if="profileStep == 1">
            <PersonalnfoPage />
          </div>
          <div v-if="profileStep == 2">
            <ContactInfoSection />
          </div>
          <div v-if="profileStep == 3">
            <HouseholdInformationSection />
          </div>
          <div v-if="profileStep == 4">
            <EligibilitySection />
          </div>
          <div v-if="profileStep == 5">
            <ProfileCompletedSection />
          </div>
        </div>
      </div>
    </div>
  </section>

  <div v-if="profileStep == 5">
    <ContactSection />
  </div>
</template>

<script>
import ProflieBreadcrumbs from "../../../components/helpers/ProfileBreadcrumbs.vue";

import PersonalnfoPage from "../../sections/Profile/01_PersonalnfoSection.vue";
import ContactInfoSection from "../../sections/Profile/02_ContactInfoSection.vue";
import HouseholdInformationSection from "../../sections/Profile/03_HouseholdInformationSection.vue";
import EligibilitySection from "../../sections/Profile/04_EligibilitySection.vue";
import ProfileCompletedSection from "../../sections/Profile/05_ProfileCompletedSection.vue";

import ContactSection from "../../sections/Profile/ContactSection.vue";

export default {
  components: {
    ProflieBreadcrumbs,
    PersonalnfoPage,
    ContactInfoSection,
    HouseholdInformationSection,
    EligibilitySection,
    ProfileCompletedSection,
    ContactSection,
  },
  computed: {
    profileStep() {
      return this.$route.query.profileStep;
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
@import "../../../assets/styles/components/pages/Profile/ProfilePage.css";
</style>
