<template>
  <section class="section section--bottom--space">
    <div>
      <div class="row">
        <template
          :key="index"
          v-for="(simSubscription, index) in SIMforSubscriber"
        >
          <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <div class="section-tariff-card">
              <div class="section-tariff-name">
                {{
                  simSubscription?.simSubscription.planSubscription.plan.name
                }}
              </div>
              <div class="section-tariff-number">
                {{ simSubscription.esn }}
              </div>
              <div
                class="section-tariff-container d-flex flex-space-between mb-32"
              >
                <div class="flex-item">Call Min</div>
                <div class="flex-item">
                  <ProgressBarCALL
                    :simId="simSubscription.id"
                    :maxValue="
                      simSubscription?.simSubscription.planSubscription.plan
                        .callMinutes
                    "
                  />
                </div>
                <div class="flex-item text-right">
                  <button
                    class="btn-small btn-black-ghost"
                    v-on:click="openModal()"
                  >
                    Extend
                  </button>
                </div>
              </div>
              <div
                class="section-tariff-container d-flex flex-space-between mb-32"
              >
                <div class="flex-item">SMS</div>
                <div class="flex-item">
                  <ProgressBarSMS
                    :simId="simSubscription.id"
                    :maxValue="
                      simSubscription?.simSubscription.planSubscription.plan.sms
                    "
                  />
                </div>
                <div class="flex-item text-right">
                  <button
                    class="btn-small btn-black-ghost"
                    v-on:click="openModal(simSubscription.simSubscription.id)"
                  >
                    Extend
                  </button>
                </div>
              </div>
              <div
                class="section-tariff-container d-flex flex-space-between mb-32"
              >
                <div class="flex-item">Data</div>
                <div class="flex-item text-left">
                  <ProgressBarDATA
                    :simId="simSubscription.id"
                    :maxValue="
                      simSubscription?.simSubscription.planSubscription.plan
                        .bandwidth
                    "
                  />
                </div>
                <div class="flex-item text-right">
                  <button
                    class="btn-small btn-black-ghost"
                    v-on:click="openModal()"
                  >
                    Extend
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
  <ExtendModal :showModal="this.showModal" />
</template>

<script>
import ExtendModal from "../../helpers/Modals/ExtendModal.vue";
import ProgressBarDATA from "../../helpers/ProgressBarDATA.vue";
import ProgressBarCALL from "../../helpers/ProgressBarCALL.vue";
import ProgressBarSMS from "../../helpers/ProgressBarSMS.vue";

export default {
  components: {
    ExtendModal,
    ProgressBarDATA,
    ProgressBarCALL,
    ProgressBarSMS,
  },
  methods: {
    async fetchSIM() {
      await this.$store.dispatch("addSIMforSubscriber");
      this.SIMforSubscriber =
        this.$store.state.DashobardSectionSIMStore.SIMforSubscriber;
    },
    openModal() {
      this.showModal = true;
    },
  },
  data() {
    return {
      SIMforSubscriber: {},
      showModal: false,
    };
  },
  mounted() {
    this.fetchSIM();
  },
};
</script>

<style>
@import "../../../assets/styles/components/sections/Dashboard/01_DashobardSectionMain.css";
</style>
