import ProfileService from "../../common/services/profileService";

import { SET_Profile } from "../actions.type";

export const state = {
  profile: {},
};

export const actions = {
  async addProfile(context) {
    const data = await ProfileService.getSubscriberProfile();
    return context.commit(SET_Profile, data);
  },
};

export const mutations = {
  SET_Profile(state, payload) {
    state.profile = payload.data;
  },
};

const getters = {
  getProfile: function (state) {
    return `${state.profile}`;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
