<template>
  <div ref="paypal"></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted: function () {
    const script = document.createElement("script");
    const ClientID =
      "AVl9h7lP1Bg_KSHE_sT8tZa7r67ea-TTwOmf82Es-IzLle159xw15Yw5lRWTaqO6jQT-58NK7fE-dbvQ";
    const Currency = "USD";
    script.src = `https://www.paypal.com/sdk/js?client-id=${ClientID}&currency=${Currency}`;
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);
  },
  methods: {
    setLoaded: function () {
      window.paypal
        .Buttons({
          createOrder: () => {
            return fetch(
              "https://c61e-178-221-69-227.eu.ngrok.io/subscriber/sim/purchase",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer AUTH_TOKEN",
                },
                body: JSON.stringify({
                  paymentGateway: "paypal",
                  planId: "", // Not required
                  shippingAddress: "Address",
                  shippingState: "State",
                  shippingCountry: "Country",
                  shippingZipcode: "000000",
                }),
              }
            )
              .then((response) => response.json())
              .then((order) => order.id);
          },
          onApprove(data) {
            return fetch(
              `https://c61e-178-221-69-227.eu.ngrok.io/paypal/order/${data.orderID}/capture`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({}),
              }
            )
              .then((response) => response.json())
              .then((orderData) => {
                console.log(
                  "Capture result",
                  orderData,
                  JSON.stringify(orderData, null, 2)
                );
                const transaction =
                  orderData.purchase_units[0].payments.captures[0];
                alert(
                  `Transaction ${transaction.status}: ${transaction.id}\n\nSee console for all available details`
                );
              });
          },
          onError: (err) => {
            console.log(err);
          },
        })
        .render(this.$refs.paypal);
    },
  },
};
</script>
