<template>
  <section
    class="section section-PaymentSuccessful mt-132 section--bottom--space"
  >
    <div class="container container-1170">
      <div class="section-PaymentSuccessful-card text-center">
        <img :src="Icon" alt="" class="mb-36" />
        <h3>Payment Successful!</h3>
        <p class="main-secondary-color">Your order number: 01000556342</p>
      </div>
      <div class="section-PaymentSuccessful-btn-block mt-48 text-center">
        <router-link class="btn-round btn-green mr-16" to="/">
          Back to Home
        </router-link>
        <router-link class="btn-round" to="/CheckACPPage">
          Check Order Status
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      Icon: require("../../../assets/images/pages/PaymentSuccessfulPage/Icon.svg"),
    };
  },
};
</script>
