<template>
  <div class="ml-2 position-relative informer">
    <div class="informer-description">{{ description }}</div>
    <img class="informer-image" :src="iconInformer" alt="" />
  </div>
</template>

<script>
export default {
  props: ["description"],
  data() {
    return {
      iconInformer: require("../../assets/images/pages/Dashboard/DashboardPage/Informer.svg"),
    };
  },
};
</script>

<style>
@import "./../../assets/styles/helpers/informer.css";
</style>
