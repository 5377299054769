import { SET_CallLogs } from "../actions.type";

export const state = {
  callLogs: {},
};

export const actions = {
  async addCallLogs(context, payload) {
    return context.commit(SET_CallLogs, payload);
  },
};

export const mutations = {
  SET_CallLogs(state, payload) {
    state.callLogs = payload;
  },
};

const getters = {
  getCallLogs: function (state) {
    return `${state.callLogs}`;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
