import { createStore } from "vuex";

// import DashboardSectionMainStore from "../store/DashboardStore/DashboardSectionMainStore";
import DashobardOrderStatusStore from "../store/DashboardStore/DashobardOrderStatusStore";
import DashobardSectionInvoiceStore from "../store/DashboardStore/DashobardSectionInvoiceStore";
import DashobardSectionMyProfileStore from "../store/DashboardStore/DashobardSectionMyProfileStore";
import DashobardCallLogsStore from "../store/DashboardStore/DashobardCallLogStore";
// import DashobardSectionPaymentMethodStore from "../store/DashboardStore/DashobardSectionPaymentMethodStore";
import RoleUserStore from "../store/DashboardStore/RoleUserStore";
import DashboardNewTab from "../store/DashboardStore/DashboardNewTab";
import DashobardSectionSIMStore from "../store/DashboardStore/DashobardSectionSIMStore";

const store = createStore({
  modules: {
    // DashboardSectionMainStore,
    DashobardOrderStatusStore,
    DashobardSectionInvoiceStore,
    DashobardSectionMyProfileStore,
    // DashobardSectionPaymentMethodStore,
    DashobardCallLogsStore,
    RoleUserStore,
    DashboardNewTab,
    DashobardSectionSIMStore,
  },
});

export default store;
